/*
 * @Author: shiguang
 * @Date: 2023-04-26 10:23:12
 * @LastEditors: lihwang_wf@126.com
 * @LastEditTime: 2024-01-11 15:40:23
 * @Description: utils
 */
import dayjs from 'dayjs';
import qs from 'query-string';
import { Config } from '@/config';
import { STATIC_URL } from '@/const/staticURL';
import { GetKeyByMap, GetValueByMap } from './type';
import { dispatchEventToNative } from './jsBridge';

enum ENUM_PICK_MAP_TYPE {
	/** 选择 map 中部分 key 映射到 options */
	PICK = 1,
	/** 剔除 map 中部分 key 映射到 options */
	OMIT = 2,
	/** 所有 map 中 key 映射到 options */
	ALL = 3
}

const originMapToOptions =
	<T extends Map<any, any>>(map: T) =>
	(type: ENUM_PICK_MAP_TYPE) =>
	(keys: GetKeyByMap<T>[]) =>
	<Fn extends (val: GetValueByMap<T>[0]) => any>(
		mapValue?: Fn
	): {
		value: GetKeyByMap<T>;
		label: typeof mapValue extends undefined
			? GetKeyByMap<T>
			: ReturnType<Fn>;
	}[] => {
		const list = Array.from(map.entries()).filter(([value]) => {
			if (type === ENUM_PICK_MAP_TYPE.OMIT && !keys.includes(value))
				return true;
			if (type === ENUM_PICK_MAP_TYPE.PICK && keys.includes(value))
				return true;
			if (type === ENUM_PICK_MAP_TYPE.ALL) return true;
			return false;
		});
		return list.map(([value, label]) => ({
			value,
			label: mapValue ? mapValue(label) : label
		}));
	};

/**
 * map options
 * @param map
 * @param mapValue
 * @returns
 */
export const mapToOptions = <
	T extends Map<any, any>,
	Fn extends (val: GetValueByMap<T>) => any
>(
	map: T,
	mapValue?: Fn
) => {
	return originMapToOptions(map)(ENUM_PICK_MAP_TYPE.ALL)([])(mapValue);
};

export const mapToOptionsByOmitKeys = <
	T extends Map<any, any>,
	Fn extends (val: GetValueByMap<T>[0]) => any
>(
	map: T,
	keys: GetKeyByMap<T>[],
	mapValue?: Fn
) => {
	return originMapToOptions(map)(ENUM_PICK_MAP_TYPE.OMIT)(keys)(mapValue);
};

export const mapToOptionsByPickKeys = <
	T extends Map<any, any>,
	Fn extends (val: GetValueByMap<T>[0]) => any
>(
	map: T,
	keys: GetKeyByMap<T>[],
	mapValue?: Fn
) => {
	return originMapToOptions(map)(ENUM_PICK_MAP_TYPE.PICK)(keys)(mapValue);
};

/**
 * 获取查询字符串中的值
 * @param name query string 的 key
 * @returns value
 */
export const getQueryStringValueByName = (name: string) => {
	return new URLSearchParams(window.location.search).get(name);
};

export const secureJSONParse = <T>(str: any, defaultValue?: T) => {
	type SecureJSONParseStore = [Error | undefined, T | undefined];
	const res: SecureJSONParseStore = [undefined, undefined];
	try {
		const obj = JSON.parse(str);
		res[1] = obj ?? defaultValue;
	} catch (e) {
		res[0] = e as Error;
		if (defaultValue) res[1] = defaultValue;
	}
	return res;
};

export const getSessionStorageByKey = <T>(key: string, defaultValue?: T) => {
	const jsonString = window.sessionStorage.getItem(key);
	return secureJSONParse(jsonString, defaultValue);
};

export const getLocalStorageByKey = <T>(key: string, defaultValue?: T) => {
	const jsonString = window.localStorage.getItem(key);
	return secureJSONParse(jsonString, defaultValue);
};

export const goLogin = () => {
	window.location.href = `${STATIC_URL.login}?redirect=${encodeURIComponent(
		window.location.href
	)}`;
};

export const toThousands = (x?: number | string) => {
	if (!x) {
		return x;
	}
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// 添加金额分隔符
export function formatMoney(
	num: number | string | void,
	returnVal = true
): string {
	// return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
	if (num !== 0 && (!num || isNaN(Number(num)))) return '';
	return returnVal
		? (Math.round(Number(num) * 100) / 100)
				.toLocaleString('en-us')
				.replace(/(\.\d\d)\d$/, '$1')
		: num.toLocaleString('en-us');
}
/** 防抖函数 */
export function debounce(fn, delay) {
	let timerId;

	return function (...args) {
		clearTimeout(timerId);
		timerId = setTimeout(() => {
			fn(...args);
		}, delay);
	};
}

/** 防抖函数 */
export function debounce2(fn, delay) {
	let timerId;

	return function (...args) {
		if (timerId) return;
		fn(...args);
		timerId = setTimeout(() => {
			clearTimeout(timerId);
			timerId = undefined;
		}, delay);
	};
}

export const groupArray = <T = any>(array: T[], subGroupLength: number) => {
	let index = 0;
	const newArray: T[][] = [];
	while (index < array.length) {
		newArray.push(array.slice(index, (index += subGroupLength)));
	}
	return newArray;
};

/**
 * 格式化日期 cn->jp
 * @param formatStr
 * @returns
 */
export const formatDateCN2JP = (
	date?: dayjs.Dayjs | string,
	formatStr?: string
): string => {
	const format = formatStr || 'YYYY-MM-DD HH:mm:ss';
	if (!date) {
		return '';
	}
	if (typeof date === 'string') {
		return dayjs(date).add(1, 'hour').format(format);
	}
	return date.add(1, 'hour').format(format);
};

/**
 * 格式化日期 jp->cn
 * @param formatStr
 * @returns
 */
export const formatDateJP2CN = (
	date?: dayjs.Dayjs | string,
	formatStr?: string
): string => {
	const format = formatStr || 'YYYY-MM-DD HH:mm:ss';
	if (!date) {
		return '';
	}
	if (typeof date === 'string') {
		return dayjs(date).add(-1, 'hour').format(format);
	}
	return date.add(1, 'hour').format(format);
};
interface jumpToAppProps {
	isVip?: boolean;
	productCode?: string;
	/** 跳转链接 */
	jumpLink?: string;
}
/**
 * 跳到app
 */
export const jumpToApp = async ({
	isVip = false,
	productCode,
	jumpLink
}: jumpToAppProps) => {
	// 如果是app的话
	if (window?.ReactNativeWebView) {
		const arr = jumpLink?.split('?') ?? [];
		const obj = qs.parse(arr[1] ?? '');
		await dispatchEventToNative({
			type: 'DIRCT_goToPurchase',
			payload: { params: { ...obj }, link: arr[0] ?? '' }
		});
		return;
	}
	const iosLinkUrl = 'https://apps.apple.com/us/app/theckb/id1669471879';
	const androidLinkurl = 'https://www.pgyer.com/2eDT';
	const userAgent = window.navigator.userAgent;
	/** ios终端 */
	const isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
	if (isIOS) {
		const _url = jumpLink
			? `com.googleusercontent.apps.920845812126-er5gg36gjv79u7bbphmqnnqs11f1hj2f://${jumpLink}`
			: `com.googleusercontent.apps.920845812126-er5gg36gjv79u7bbphmqnnqs11f1hj2f://HomeScreen`;
		// 先跳首页，等app审核完毕再正常跳转
		// const _url = `com.googleusercontent.apps.920845812126-er5gg36gjv79u7bbphmqnnqs11f1hj2f://HomeScreen`;
		window.location.href = _url;
		// if (!isVip) {
		// }
		setTimeout(() => {
			// 没找到打开应用商店
			window.location.href = iosLinkUrl;
			// window.open(iosLinkUrl, '_blank');
		}, 2000);
		return;
	}
	// 先跳首页，等app审核完毕再正常跳转
	// const _url = `com.theckbsniffmobile.braintree://HomeScreen`;
	const _url = jumpLink
		? `com.theckbsniffmobile.braintree://${jumpLink}`
		: `com.theckbsniffmobile.braintree://HomeScreen`;
	window.location.href = _url;
	setTimeout(() => {
		// 没找到打开下载地址
		window.location.href = androidLinkurl;
		// window.open(androidLinkurl, '_blank');
	}, 2000);
};

// 文件转换为base64
export const searchByBase64 = (file: any): Promise<any> => {
	return new Promise((resolve, reject) => {
		let canvas;
		const URL = window.URL || window.webkitURL;
		const blob = URL.createObjectURL(file);
		const img = new Image();
		img.src = blob;
		img.onload = function () {
			const that = this as any;
			const rate = file.size > 200 * 1024 ? 0.5 : 1;

			const w = that.width * rate;
			const h = that.height * rate;
			// 生成canvas
			canvas = document.createElement('canvas');
			canvas.width = w;
			canvas.height = h;
			const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
			// document.append(canvas)
			ctx.drawImage(that, 0, 0, w, h);
			const base64 = canvas.toDataURL('image/jpeg', rate);
			// }
			resolve({ status: true, data: base64 });
		};
		img.onerror = () => {
			resolve({ status: false });
		};
	});
};
/** 压缩之后的base64 */
function changeUrlToBase64Compress({ url, size }) {
	return new Promise((resolve, reject) => {
		let canvas;
		const img = new Image();
		img.crossOrigin = 'Anonymous';
		img.src = url;
		img.onload = function () {
			const that = this as any;
			const rate = size > 200 * 1024 ? 0.5 : 1;

			const w = that.width * rate;
			const h = that.height * rate;
			// 生成canvas
			canvas = document.createElement('canvas');
			canvas.width = w;
			canvas.height = h;
			const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
			// document.append(canvas)
			ctx.drawImage(that, 0, 0, w, h);
			const base64 = canvas.toDataURL('image/jpeg', rate);
			// }
			resolve({ status: true, data: base64 });
		};
		img.onerror = () => {
			resolve({ status: false });
		};
	});
}
/** url转为base64 */
async function changeUrlToBase64(url) {
	try {
		// 获取文件大小
		const response = await fetch(url);
		const contentLength = response.headers.get('content-length');
		const fileSize = parseInt(contentLength!, 10);
		/** 压缩之后的 */
		const data: any = await changeUrlToBase64Compress({
			url,
			size: fileSize
		});
		if (!data.status) return '';
		return data.data;
	} catch (error) {
		// throw new Error('处理过程中出错: ' + error.message);
	}
}
/** base64图搜上传，返回imageId */
export const changeBase64ToImageId = async ({ url, request }) => {
	const data = await changeUrlToBase64(url);
	const uploadUrlRes = await request({
		// imageUrl: url,
		imageBase64Content: data?.split('data:image/jpeg;base64,')[1]
	});
	return { imageId: uploadUrlRes.data ?? '', imageUrl: url };
};

// 判断访问终端

export function isMobile() {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
		navigator.userAgent
	);
}

/**
 * 通过cdn地址替换图片地址
 * @param imgUrl 图片地址
 * @returns
 */
export const changeImageCdn = (
	imgUrl: string | undefined = '',
	size: number | undefined = 0
) => {
	const imgPart = imgUrl?.split('.') || [];
	const reg = /(png|jpg|jpeg|bmp|webp)/;
	if (!reg.test(imgPart[imgPart.length - 1])) {
		return imgUrl;
	}
	if (
		(imgUrl.includes('.theckbs.com') || imgUrl.includes('.theckb.com')) &&
		!imgUrl.includes('x-oss-process')
	) {
		let url =
			imgUrl +
			(size
				? `?x-oss-process=image/resize,w_${size},limit_0,/format,webp`
				: `?x-oss-process=image/format,webp`);
		if (url.includes('jpg')) {
			url += '/quality,Q_80';
		}
		return url;
	}

	if (imgUrl?.indexOf('.alicdn.com') > -1) {
		// 正则替换imgUrl中的域名cbu01.alicdn.com为global-img-cdn.1688.com
		let newImgUrl = imgUrl.replace(
			/cbu01.alicdn.com/g,
			'global-img-cdn.1688.com'
		);
		const urlList = newImgUrl.split('.');
		// 如果商品详情有size参数，则不替换
		if (size && !/\dx\d/.test(urlList[urlList.length - 2])) {
			const maxSize = size > 375 ? 375 : size;
			urlList.splice(imgPart.length - 1, 0, `${maxSize}x${maxSize}`);
			newImgUrl = urlList.join('.');
		}
		return newImgUrl;
	}

	return imgUrl;
};
