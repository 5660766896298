/*
 * @Author: shiguang
 * @Date: 2023-04-25 10:46:45
 * @LastEditors: yusha
 * @LastEditTime: 2023-11-30 10:13:29
 * @Description: shiguang
 */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './config/arms-ali';
import App from './App';
import './styles/theme/fonts/font.scss';
import './i18n/i18n';
import './index.scss';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(<App />);
