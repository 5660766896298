/*
 * @Author: yusha
 * @Date: 2023-10-20 13:22:13
 * @LastEditors: yusha
 * @LastEditTime: 2023-12-13 11:07:38
 * @Description: layout-navbar
 */

import { HTMLAttributes, memo, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import Return from '@/common/icons/Return';
import { RouterHistoryContext } from '@/App';
import BackToHome from '@/component/BackToHome';
import { dispatchEventToNative } from '@/utils/jsBridge';
import './index.scss';
interface CommonGoBackInter {
	onBack?: () => void;
	navigate: any;
	curPage: any;
	fromSniffApp: string;
}
export const commonGoBack = async ({
	onBack,
	navigate,
	curPage,
	fromSniffApp
}: CommonGoBackInter) => {
	// 如果是嵌入app，且路由有这个字段fromSniffApp
	if (window?.ReactNativeWebView && fromSniffApp) {
		await dispatchEventToNative({
			type: 'DIRCT_goNativeBack',
			payload: {}
		});
	}
	if (onBack) {
		return onBack();
	}
	navigate(-1);
};
interface CustomNavbarProps extends HTMLAttributes<HTMLDivElement> {
	navBarRight?: React.ReactNode;
	title?: string;
	onBack?: () => void;
	/** 是否展示tabbar，用于不展示返回键 */
	isShowBackIcon?: boolean;
	// 是否固定
	fixed?: boolean;
	showBackHome?: boolean;
}
const CustomNavbar = (props: CustomNavbarProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const {
		title,
		navBarRight,
		className,
		onBack,
		isShowBackIcon = true,
		fixed = false,
		showBackHome,
		...divRestProps
	} = props;
	const routerHistory = useContext(RouterHistoryContext);
	const [curPage, prepage] = routerHistory;
	/** 返回 */
	const changeBack = async () => {
		// 如果是路由带过来的参数
		const searchObj = qs.parse(location.search);
		const fromSniffApp = searchObj.fromSniffApp as string;
		// fromSniffApp
		commonGoBack({
			onBack,
			navigate,
			curPage,
			fromSniffApp
		});
	};
	return (
		<div
			{...divRestProps}
			className={`layout-header ${className ?? ''}`}
			style={{
				position: fixed ? 'fixed' : 'relative'
			}}
		>
			{((isShowBackIcon && window.history.length > 1) ||
				window?.ReactNativeWebView) && (
				<div className="left-style" onClick={() => changeBack()}>
					<Return
						style={{
							width: '0.14rem',
							height: '0.14rem'
						}}
					/>
				</div>
			)}
			{showBackHome && <BackToHome />}
			<div className="title-style">{title ?? ''}</div>
			<div className="right-style">{navBarRight && navBarRight}</div>
		</div>
	);
};
export default memo(CustomNavbar);
