/*
 * @Author: huajian
 * @Date: 2023-10-31 18:38:31
 * @LastEditors: lihwang_wf@126.com
 * @LastEditTime: 2023-12-12 11:40:08
 * @Description: 枚举中心
 */

/** 转化为code为键值 */
const setCodeKey = (enumObj: {
	[key: string]: {
		code: number;
		cn: string;
	};
}) => {
	Object.keys(enumObj).forEach((key) => {
		enumObj[enumObj[key].code] = enumObj[key];
	});
};

/** 订单状态 */
export const EnumOrderStatus = {
	waitPay: {
		code: 0,
		cn: window._$m.t('待支付'),
		icon: 'https://static-s.theckb.com/BusinessMarket/Easy/H5/待支付.png'
	},
	waitShip: {
		code: 1,
		cn: window._$m.t('待发货'),
		icon: 'https://static-s.theckb.com/BusinessMarket/Easy/H5/待发货.png'
	},
	shipped: {
		code: 2,
		cn: window._$m.t('已发货'),
		icon: 'https://static-s.theckb.com/BusinessMarket/Easy/H5/已发货.png'
	},
	cancelled: {
		code: 3,
		cn: window._$m.t('已取消'),
		icon: 'https://static-s.theckb.com/BusinessMarket/Easy/H5/取消@2x.png'
	},
	closed: {
		code: 4,
		cn: window._$m.t('已关闭'),
		icon: 'https://static-s.theckb.com/BusinessMarket/Easy/H5/关闭@2x.png'
	}
};
setCodeKey(EnumOrderStatus);

/** 费用类型 */
export const EnumBizType = {
	ALL: {
		code: '',
		cn: window._$m.t('全部')
	},
	PAY: {
		code: 'PAY',
		cn: window._$m.t('交易支付')
	},
	RECHARGE: {
		code: 'RECHARGE',
		cn: window._$m.t('充值')
	}
};
export type EnumBizType = keyof typeof EnumBizType;
export const EnumPayTypeCode = {
	WALLET: {
		code: 'WALLET',
		cn: window._$m.t('钱包支付')
	},
	PAYPAL: {
		code: 'PAYPAL',
		cn: window._$m.t('PAYPAL支付')
	},
	PAYPAL_CREDIT: {
		code: 'PAYPAL_CREDIT',
		cn: window._$m.t('PAYPAL信用卡')
	}
};
export const EnumDeliveryStatus = {
	pending: {
		code: 0,
		cn: window._$m.t('待处理')
	},
	picking: {
		code: 1,
		cn: window._$m.t('拣货中')
	},
	marking: {
		code: 2,
		cn: window._$m.t('打标中')
	},
	packaging: {
		code: 3,
		cn: window._$m.t('打包中')
	},
	packaged: {
		code: 4,
		cn: window._$m.t('打包完成')
	},
	shipping: {
		code: 5,
		cn: window._$m.t('已发货')
	},
	cancelled: {
		code: 6,
		cn: window._$m.t('已取消')
	}
};
setCodeKey(EnumDeliveryStatus);
export const EnumRechargeStatus = {
	BUSINESS: {
		code: 'BUSINESS',
		cn: window._$m.t('待初审')
	},
	FINAL: {
		code: 'FINAL',
		cn: window._$m.t('待终审')
	},
	REFUSE: {
		code: 'REFUSE',
		cn: window._$m.t('已拒绝')
	},
	SUCCESS: {
		code: 'SUCCESS',
		cn: window._$m.t('已通过')
	}
};
export const EnumStationList = {
	JAPAN: 'JapanStation',
	KOREA: 'KoreaStation'
} as const;

/** 商品售卖状态 */
export enum Enum_SellStatus {
	/** 正常 */
	Normal = 1,
	/** 售罄 */
	SoldOut = 2,
	/** 下架 */
	delist = 3
}
export enum EnumProductPurchaseType {
	/** 直采 */
	DIRECT,
	/** 代采 */
	PROXY
}
// 商品类型
export enum PRODUCT_TYPE {
	// 0-线上，1-线下
	ON_LINE = 0,
	OFF_LINE = 1
}
export enum PRODUCT_STATUS_TYPE {
	/** 0-待售卖 */
	PENDING_SALE = 0,
	/** 1-售卖中 */
	SELLING = 1,
	/** 2-待下架 */
	PENDING_OFFLINE = 2,
	/** 停止售卖 */
	STOP_SALE = 3,
	/** 4-删除 */
	DELETE = 4
}

// 活动类型枚举
export enum ACTIVITY_TYPE {
	/** 一口价格活动 */
	PRICE = 1,
	/** 折扣率活动 */
	DISCOUNT_RATE = 0
}
