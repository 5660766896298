import { serviceConfig } from '@/config/request/swaggerServiceConfig';
import { Api as Customer } from './customer';
import { Api as Pay } from './pay';
import { Api as EasyOrder } from './easyOrder';
import { Api as EasyGoods } from './easyGoods';
import { Api as EasyMarket } from './easyMarket';

export const Api = { Customer, Pay, EasyOrder, EasyGoods, EasyMarket };

const warpperServiceConfig = (
	serviceConfig: any,
	ctx: { name: string; basePath: string }
) => {
	const newConfig = { ...serviceConfig };
	if (newConfig.baseURL) {
		newConfig.baseURL = newConfig.baseURL + ctx.basePath;
	}
	return newConfig;
};

const customer = new Customer(
	warpperServiceConfig(serviceConfig, {
		name: 'customer',
		basePath: '/customer'
	})
);
const pay = new Pay(
	warpperServiceConfig(serviceConfig, { name: 'pay', basePath: '/pay' })
);
const easyOrder = new EasyOrder(
	warpperServiceConfig(serviceConfig, {
		name: 'easyOrder',
		basePath: '/easy/order'
	})
);
const easyGoods = new EasyGoods(
	warpperServiceConfig(serviceConfig, {
		name: 'easyGoods',
		basePath: '/easy/goods'
	})
);
const easyMarket = new EasyMarket(
	warpperServiceConfig(serviceConfig, {
		name: 'easyMarket',
		basePath: '/market'
	})
);

export const apiInstanceList = [
	{ key: 'customer', instance: customer },
	{ key: 'pay', instance: pay },
	{ key: 'easyOrder', instance: easyOrder },
	{ key: 'easyGoods', instance: easyGoods },
	{ key: 'easyMarket', instance: easyMarket }
];

export const api = { customer, pay, easyOrder, easyGoods, easyMarket };
