/*
 * @Author: yusha
 * @Date: 2023-10-27 10:55:30
 * @LastEditors: huajian
 * @LastEditTime: 2023-10-31 16:05:57
 * @Description:
 */

import { atom } from 'jotai';
import { OrderProductDTO } from '@/service/easyOrder';

interface CartData {
	/** 有效数量 */
	cartNum: number;
	/** 购物车列表 */
	cartList: OrderProductDTO[];
}

/** 购物车相关数据 */
const cartOriginalData = atom<CartData>({
	cartNum: 0,
	cartList: []
});

const atomCartOriginalData = atom(
	(get) => get(cartOriginalData),
	(_get, set, data: CartData) => {
		console.log('=============', data);
		set(cartOriginalData, data);
	}
);

export default { cartOriginalData, atomCartOriginalData };
