import { useNavigate } from 'react-router-dom';
import { dispatchEventToNative } from '@/utils/jsBridge';
import Hoursehome from '@/common/icons/Househome';
import styles from './index.module.scss';
function BackToHome() {
	const navigate = useNavigate();
	const goHome = async () => {
		// 如果是嵌入app
		if (window?.ReactNativeWebView) {
			await dispatchEventToNative({
				type: 'DIRCT_goHome',
				payload: {}
			});
			return;
		}
		navigate('/goods/home', {
			replace: true
		});
	};
	return (
		<div className={styles.backToHome}>
			<Hoursehome size={16} onClick={goHome} />
		</div>
	);
}
export default BackToHome;
