/*
 * @Author: lihwang
 * @Date: 2023-04-28 11:51:00
 * @LastEditors: huajian
 * @LastEditTime: 2024-01-12 00:26:07
 * @Description: interceptors
 */
import Cookie from 'js-cookie';
import md5 from 'crypto-js/md5';
import { api } from '@/service';

import { apiTag } from './interceptors';

// 登录环境
export type loginEnv = 'dev' | 'test' | 'pre' | 'prod' | 'prod1';
/**
 * 存在cookie的token名称
 */
export const TokenSignCookie: string =
	process.env.REACT_APP_ENV + '_route/token';
/**
 * 存储cookie的环境
 */
export const cookieEnv =
	process.env.NODE_ENV === 'production'
		? '.3fbox.com'
		: window.location.hostname;

interface MockLogin {
	password: string;
	nameOrEmail: string;
	verificationCode: string;
	customerMobile: string;
}

export const getLoginToken = async ({
	password,
	nameOrEmail,
	verificationCode,
	customerMobile
}: MockLogin) => {
	logout();
	let loginRes;
	// 手机号码验证码登录
	if (verificationCode && customerMobile) {
		loginRes = await api.customer.mobileLogin.mobileLogin({
			customerMobile,
			/** 国际区号 */
			globalRoaming: '+81',
			/** @format int32 */
			platformType: 1,
			verificationCode
		});
	} else {
		loginRes = await api.customer.passwordLogin.passwordLogin({
			password: md5(password).toString(),
			nameOrEmail,
			platformType: 1
		});
	}

	if (loginRes.data?.token) {
		Cookie.set(TokenSignCookie, loginRes.data?.token ?? '', {
			path: '/',
			domain: cookieEnv
		});
		const res = await api.customer.changeLoginByType.changeLoginByType({
			type: 3
		});
		const fistShopId =
			res.data?.customerShopList?.[0]?.customerShopId || '';
		window.localStorage.setItem(
			'production_route/token',
			res.data?.token ?? ''
		);
		window.localStorage.setItem(
			'production_route/curShop',
			String(fistShopId)
		);
		// console.log
		if (res.data?.token) return res.data?.token;
	}
	return '';
};

export const checkLogin = async () => {
	const token = window.localStorage.getItem('production_route/token');
	const cookieToken = Cookie.get(TokenSignCookie);
	if (cookieToken) {
		if (token === cookieToken) {
			return token;
		}
		try {
			const res = await api.customer.changeLoginByType.changeLoginByType({
				type: 3
			});
			const fistShopId =
				res.data?.customerShopList?.[0]?.customerShopId || '';
			window.localStorage.setItem(
				'production_route/token',
				res.data?.token ?? ''
			);
			window.localStorage.setItem(
				'production_route/curShop',
				String(fistShopId)
			);
			return res.data?.token;
		} catch {
			logout();
			return '';
		}
	}
	logout();
	return '';
};

/** 退出清除信息 */
export const logout = () => {
	window.localStorage.removeItem('production_route/curShop');
	window.localStorage.removeItem('production_route/token');
	Cookie.remove(TokenSignCookie, { domain: cookieEnv });
};
