/*
 * @Date: 2023-12-11 10:55:50
 * @LastEditors: lihwang_wf@126.com
 * @LastEditTime: 2024-01-19 14:49:10
 * @FilePath: /ckb-easy-h5-fed/src/config/buryingPoint.ts
 * @Description:
 */

import { loginEnv } from './request/login';
const _env = process.env.REACT_APP_ENV as loginEnv;
const linkerUrl = {
	test: ['test-m.3fbox.com', 'test-m.3fbox.com'],
	pre: ['pre-m.3fbox.com', 'pre-m.3fbox.com'],
	prod: ['m.3fbox.com', 'm.3fbox.com']
}[_env] || ['m.3fbox.com', 'm.3fbox.com'];

// 业务类型
export enum BuType {
	'ZC' = 0,
	'DC' = 1
}
/** 平台类型 */
enum PlatFormType {
	EASY_H5 = 4,
	EASY_APP = 5
}

/** 数数埋点初始化 */
const thinkingdata = require('thinkingdata-browser');

const initThinkingData = (isInWebview = false) => {
	thinkingdata.init({
		appId:
			_env === 'prod'
				? '2da850ddb9c04769a0b0de300767a72f'
				: 'a6f9d0a2f07149c0acc53a20db8d69da',
		serverUrl: 'https://global-receiver-ta.thinkingdata.cn',
		batch: true,
		send_method: 'ajax'
	});

	thinkingdata.quick('siteLinker', {
		linker: [
			{ part_url: linkerUrl[0], after_hash: false },
			{ part_url: linkerUrl[1], after_hash: false }
		]
	});
	// 获取本地时间
	const localTime = new Date();
	// 计算UTC偏移量（以分钟为单位）
	const utcOffset = (localTime.getTimezoneOffset() / 60) * -1;
	thinkingdata.setSuperProperties({
		env: process.env.REACT_APP_ENV,
		zone_offset: utcOffset,
		platform_type: isInWebview
			? PlatFormType.EASY_APP
			: PlatFormType.EASY_H5,
		bu_type: BuType.ZC
	});
};
// if (route) {
// 	user.channel = route.query.channel;
// 	user.channel_group = route.query.channel_group;
// }
/** 数数 设置用户 */
export const taSetUser = (customerRespDTO: any = {}, otherParams: any = {}) => {
	console.log(customerRespDTO, 'customerRespDTO');

	const localTime = new Date();
	// 计算UTC偏移量（以分钟为单位）
	const utcOffset = (localTime.getTimezoneOffset() / 60) * -1;
	const timeZone = utcOffset >= 0 ? `UTC+${utcOffset}` : `UTC${utcOffset}`;
	const user: any = {
		bu_type: BuType.ZC,
		customer_id: customerRespDTO.customerId,
		customer_name: customerRespDTO.customerName,
		station_code: customerRespDTO.stationCode,
		super_customer_id: customerRespDTO.superCustomerId,
		system_source: customerRespDTO.systemSource,
		time_zone: timeZone,
		internal_flag: customerRespDTO.internalFlag,
		login_name: customerRespDTO.loginName,
		main_customer_id: customerRespDTO.mainCustomerId,
		nation_code: customerRespDTO.nationCode,
		unification_customer_full_name:
			customerRespDTO.unificationCustomerFullName,
		platform_type: customerRespDTO.platformType,
		...otherParams
	};

	thinkingdata.login(customerRespDTO?.customerId);
	thinkingdata.userSet(user);
};

// ta 上报时间
enum TaEvent {
	/** 通用浏览/曝光用 page_view 事件  */
	PAGE_VIEW = 'page_view',
	/** 通用点击用 btn_click 事件 */
	BTN_CLICK = 'btn_click',
	/** Banner点击 */
	BANNER_CLICK = 'banner_click',
	/** 商品搜索 */
	PRODUCT_SEACH = 'product_seach',
	/** 限时活动点击 */
	LIMITED_TIME_ACTIVITY_CLICK = 'limited_time_activity_click',
	/** 商品点击 */
	PRODUCT_CLICK = 'product_click',
	/** 商品详情 */
	PRODUCT_DETAIL_PAGEVIEW = 'product_detail_pageview',
	/** 商品收藏 */
	PRODUCT_COLLECT = 'product_collect',
	/** 加入购物车 */
	ADD_SHOPPING_CART = 'add_shopping_cart',
	/** 购物车点击 */
	SHOPPING_CART_CLICK = 'shopping_cart_click',
	/** 商详购买 */
	PRODUCT_BUY = 'product_buy',
	/** 购物车浏览 */
	SHOPPING_CART_PAGEVIEW = 'shopping_cart_pageview',
	/** 提交订单 */
	ORDER_SUBMIT = 'order_submit',
	/** 订单支付 */
	ORDER_PAY = 'order_pay',
	/** 订单支付点击 */
	ORDER_PAY_CLICK = 'order_pay_click',
	/** 取消支付 */
	CANCELL_PAY = 'cancell_pay'
}

interface TaEventParams {
	/** 上报事件 */
	event: TaEvent;
	/** 上报参数 */
	value?: Record<string, any>;
}

/**
 * 数数事件上报
 * @param {TaEventParams} params
 */
const taTrack = (params: TaEventParams) => {
	const { event, value = {} } = params;
	const sendParams = { ...value } as any;
	thinkingdata.track(
		// 追踪事件的名称
		event,
		// 需要上传的事件属性
		sendParams
	);
};

export { initThinkingData, thinkingdata, taTrack, TaEvent };
