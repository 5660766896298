/*
 * @Author: tianzhitong laotianwy@163.com
 * @Date: 2023-10-18 13:54:06
 * @LastEditors: lihwang_wf@126.com
 * @LastEditTime: 2023-12-11 16:13:34
 * @FilePath: /ckb-easy-h5-fed/src/config/router/goods.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { lazy } from 'react';

/** 首页路由名字 */
export const ROUTER_INDEX_NAME = '/goods/home';

export const ROUTER_GOODS = [
	{
		path: ROUTER_INDEX_NAME,
		title: 'CKB | 1688直营商城',
		component: lazy(() => import('../../container/goods/Home'))
	},
	// {
	// 	path: '/goods/home',
	// 	title: '新首页',
	// 	component: lazy(() => import('../../container/goods/Home'))
	// },
	{
		path: '/goods/list',
		title: '商品列表',
		component: lazy(() => import('../../container/goods/List'))
	},
	{
		path: '/goods/imageSearchList',
		title: '图搜列表',
		component: lazy(() => import('../../container/goods/ImageSearchList'))
	},
	{
		path: '/goods/detail',
		title: '商品详情',
		component: lazy(() => import('../../container/goods/Detail'))
	},
	{
		path: '/goods/search',
		title: '搜索',
		component: lazy(() => import('../../container/goods/Search'))
	},
	{
		path: '/goods/classify',
		title: 'CKB X 1688直营商城',
		component: lazy(() => import('../../container/goods/Classify'))
	}
];
