/*
 * @Author: shiguang
 * @Date: 2023-12-08 16:35:07
 * @LastEditors: yusha
 * @LastEditTime: 2023-12-19 10:31:48
 * @Description:
 */
const customEvent: any = new Event('dirctEvent');
export const bridgeCallbackStore: Record<
	string,
	Record<string, (...params: any[]) => void>
> = {
	default: {}
};

export const isAndroid = (() => {
	const userAgent = navigator.userAgent.toLowerCase();
	return userAgent.includes('android');
})();

// 指定长度和基数
function getUuid(len: number, radix: number) {
	const chars =
		'0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split(
			''
		);
	const uuid: string[] = [];
	let i: number;
	// eslint-disable-next-line no-param-reassign
	radix = radix || chars.length;

	if (len) {
		// Compact form
		for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
	} else {
		// rfc4122, version 4 form
		let r;

		// rfc4122 requires these characters
		uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
		uuid[14] = '4';

		// Fill in random data.  At i==19 set the high bits of clock sequence as
		// per rfc4122, sec. 4.1.5
		for (i = 0; i < 36; i++) {
			if (!uuid[i]) {
				r = 0 | (Math.random() * 16);
				uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
			}
		}
	}

	return uuid.join('');
}

interface HandleCallbackMessageData {
	/** 回调ID */
	callbackId: string;
	/** 回调方法的 data */
	methodData: any;
	/** 基础数据 */
	baseData: any;
}

export const handleCallbackMessage = (event: any) => {
	// if (typeof decodeURIComponent(event.nativeEvent.data) !== 'string') return;
	// const data = JSON.parse(decodeURIComponent(event.nativeEvent.data));
	let postMessageData: any = {};
	try {
		postMessageData = JSON.parse(window.decodeURIComponent(event.data));
	} catch {}
	const { callbackId, methodData, baseData } = postMessageData;
	// alert(`2222${JSON.stringify(callbackId)}`);
	if (!callbackId) return;
	customEvent.data = JSON.parse(JSON.stringify(postMessageData));
	if (isAndroid) {
		// alert(`66666${JSON.stringify(callbackId)}`);
		// 派发事件
		window.document.dispatchEvent(customEvent);
	} else {
		window.dispatchEvent(customEvent);
	}

	bridgeCallbackStore.default[callbackId]?.(methodData);
};

(() => {
	if (typeof window === undefined) return;
	// 安卓需要这样处理
	(window.onload = function () {
		window.document.addEventListener('message', handleCallbackMessage);
	})();
	// ios 需要这样处理
	if (!isAndroid) {
		window.addEventListener('message', handleCallbackMessage);
	}
})();

declare global {
	interface Window {
		ReactNativeWebView: Pick<Window, 'postMessage'>;
	}
}

/**
 * 发送消息到 native
 * @param messageObj
 * @returns
 */
export const dispatchEventToNative = (methodData: any) => {
	if (typeof window === undefined) return;
	return new Promise((resolve, reject) => {
		const messageObj: HandleCallbackMessageData = {
			methodData,
			callbackId: getUuid(8, 16),
			baseData: {}
		};
		const messageStr = window.encodeURIComponent(
			JSON.stringify(messageObj)
		);

		window?.ReactNativeWebView?.postMessage(messageStr);
		bridgeCallbackStore.default[messageObj.callbackId] = resolve;
	});
};
