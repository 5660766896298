import React, { useEffect, useRef } from 'react';
import styles from './style.module.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
  size?: string | number;
  width?: string | number;
  height?: string | number;
  spin?: boolean;
  rtl?: boolean;
  color?: string;
  fill?: string;
  stroke?: string;
}
export default function BottomnavigationHomepageDefault(props: IconProps) {
  const root = useRef<SVGSVGElement>(null);
  const {
    size = '1em',
    width,
    height,
    spin,
    rtl,
    color,
    fill,
    stroke,
    className,
    ...rest
  } = props;
  const _width = width || size;
  const _height = height || size;
  const _stroke = stroke || color;
  const _fill = fill || color;
  useEffect(() => {
    if (!_fill) {
      (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-fill]').forEach(item => {
        item.setAttribute('fill', item.getAttribute('data-follow-fill') || '');
      });
    }
    if (!_stroke) {
      (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-stroke]').forEach(item => {
        item.setAttribute('stroke', item.getAttribute('data-follow-stroke') || '');
      });
    }
  }, [stroke, color, fill]);
  return <svg ref={root} width={_width} height={_height} viewBox="0 0 18 20" preserveAspectRatio="xMidYMid meet" fill="" role="presentation" xmlns="http://www.w3.org/2000/svg" className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()} {...rest}>
          <g><path data-follow-fill="currentColor" fillRule="evenodd" d="m9.5.22 7.5 6a1 1 0 0 1 .375.78v12a1 1 0 0 1-.883.993l-.117.007h-15a1 1 0 0 1-1-1V7a1 1 0 0 1 .375-.78l7.5-6a1 1 0 0 1 1.25 0Zm-.625 2.06-6.5 5.199V18h3v-5.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1V18h3V7.48l-6.5-5.2Zm1.5 11.22h-3V18h3v-4.5Z" fill={_fill} /></g>
        </svg>;
}