/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** CartAddReq */
export interface CartAddReq {
	/** 加购sku */
	skuList?: CartAddSkuReq[];
}

/** CartAddResp */
export interface CartAddResp {
	/** 购物车id */
	cartIds?: number[];
}

/** CartAddSkuReq */
export interface CartAddSkuReq {
	/** 平台商品SKU */
	productSku?: string;
	/**
	 * 数量
	 * @format int32
	 */
	quantity?: number;
}

/** CartCalculatePriceReq */
export interface CartCalculatePriceReq {
	/** 购物车id */
	ids?: number[];
}

/** CartCalculatePriceResp */
export interface CartCalculatePriceResp {
	/** 购物车信息 */
	productList?: OrderProductDTO[];
	/** 商品总金额 */
	productTotalAmount?: number;
	/**
	 * 商品总数量
	 * @format int32
	 */
	productTotalQuantity?: number;
}

/** CartDeleteReq */
export interface CartDeleteReq {
	/** 购物车id */
	ids?: number[];
}

/** CartUpdateReq */
export interface CartUpdateReq {
	/**
	 * 购物车id
	 * @format int64
	 */
	id?: number;
	/**
	 * 数量
	 * @format int32
	 */
	quantity?: number;
}

/** OrderAdminSearchReq */
export interface OrderAdminSearchReq {
	/**
	 * 代采店铺id
	 * @format int64
	 */
	agencyPurchaseShopId?: number;
	/** 商品后台一级类目 */
	cateCodeLevel1?: string;
	/** 商品后台二级类目 */
	cateCodeLevel2?: string;
	/** 商品后台三级类目 */
	cateCodeLevel3?: string;
	/** 国际运单号 */
	logisticsCode?: string;
	/**
	 * 订单创建时间结束
	 * @format date-time
	 */
	orderCreateTimeEnd?: string;
	/**
	 * 订单创建时间开始
	 * @format date-time
	 */
	orderCreateTimeStart?: string;
	/**
	 * 子订单状态(0:待支付，1:待发货，2：已发货，3：已取消，4：已关闭)
	 * @format int32
	 */
	orderItemStatus?: number;
	/** 订单号 */
	orderNo?: string;
	/** 订单号 */
	orderNos?: string[];
	/**
	 * 主订单状态(0:待支付，1:待发货，2：已发货，3：已取消，4：已关闭)
	 * @format int32
	 */
	orderStatus?: number;
	/** 订单类型：枚举值，B2B/D2C */
	orderType?: string;
	/** 代采订单号 */
	outOrderNo?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/**
	 * 支付时间结束
	 * @format date-time
	 */
	payTimeEnd?: string;
	/**
	 * 支付时间开始
	 * @format date-time
	 */
	payTimeStart?: string;
	/** 下单人名称 */
	placeOrderName?: string;
	/** 商品名称 */
	productName?: string;
	/** 平台商品sku */
	productSku?: string;
	/** 平台商品sku */
	productSkus?: string[];
	/** 收货人名称 */
	receiveName?: string;
	/** @format int32 */
	startIndex?: number;
	/** 站点 */
	stationCode?: string;
	/**
	 * 订单类型(1:普通)
	 * @format int32
	 */
	tradeType?: number;
}

/** OrderAdminSearchResp */
export interface OrderAdminSearchResp {
	/** 实付总金额(支付回调) */
	actualAmount?: number;
	/**
	 * 代采店铺id
	 * @format int64
	 */
	agencyPurchaseShopId?: number;
	/** 扩展字段 */
	bizExt?: OrderBizExtDTO;
	/**
	 * 取消时间
	 * @format date-time
	 */
	cancelTime?: string;
	/** 关闭原因 */
	closeReason?: string;
	/**
	 * 关闭时间
	 * @format date-time
	 */
	closeTime?: string;
	/**
	 * 完成时间
	 * @format date-time
	 */
	completeTime?: string;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createdTime?: string;
	/**
	 * 客户id
	 * @format int64
	 */
	customerId?: number;
	/** 客户名称 */
	customerName?: string;
	/** 用户备注 */
	customerRemark?: string;
	/**
	 * 直采店铺id
	 * @format int64
	 */
	customerShopId?: number;
	/**
	 * 发货时间
	 * @format date-time
	 */
	deliverTime?: string;
	/** 优惠金额 */
	discountAmount?: number;
	/** 汇率快照 */
	exchangeRateSnapshot?: number;
	/** 包邮金额 */
	freeShippingAmount?: number;
	/** @format int64 */
	id?: number;
	/** 国际运费金额 */
	internationalShippingAmount?: number;
	/** 物流信息 */
	logisticsInfo?: string;
	/** 子订单列表 */
	orderItemList?: OrderItemDTO[];
	/** 主订单编号 */
	orderNo?: string;
	/** 收货地址信息 */
	orderReceivingAddress?: OrderReceivingAddressDTO;
	/**
	 * 订单状态(0：待支付，1：已支付(待发货)，2：已发货，3：已取消，4：已关闭)
	 * @format int32
	 */
	orderStatus?: number;
	/** 订单类型：枚举值，b2b/d2c */
	orderType?: string;
	/** 外部主订单编号(代采主订单编号) */
	outOrderNo?: string;
	/** 支付单信息 */
	payDetail?: string;
	/**
	 * 支付时间
	 * @format date-time
	 */
	payTime?: string;
	/** 支付类型(WALLET 钱包支付；PAYPAL paypal支付；PAYPAL_CREDIT paypal信用卡) */
	payTypeCode?: string;
	/** 应付总金额(商品总金额+国际运费) */
	payableAmount?: number;
	/** 下代采订单失败原因 */
	performErrorMessage?: string;
	/**
	 * 履约状态 0 未履约 1 下代采单成功 2 下代采单失败
	 * @format int32
	 */
	performStatus?: number;
	/** 下单人信息 */
	placeOrderAddress?: PlaceOrderAddressDTO;
	/** 下单人姓名 */
	placeOrderName?: string;
	/**
	 * 商品总数量
	 * @format int32
	 */
	productNum?: number;
	/** 商品总金额-优惠金额 */
	productTotalAmount?: number;
	/** 收货人姓名 */
	receiveName?: string;
	/** 所属店铺 */
	shopName?: string;
	/**
	 * 渠道来源 1 pc客户端 2 h5
	 * @format int32
	 */
	sourceChannel?: number;
	/** 站点代码(日本:JapanStation,韩国:KoreaStation,英国:UkStation) */
	stationCode?: string;
	/**
	 * 统一用户id
	 * @format int64
	 */
	superCustomerId?: number;
	/**
	 * 超时时间
	 * @format date-time
	 */
	timeoutTime?: string;
	/**
	 * 交易类型(1:普通)
	 * @format int32
	 */
	tradeType?: number;
	/**
	 * 更新时间
	 * @format date-time
	 */
	updatedTime?: string;
	/** 仓库编码 */
	wareCode?: string;
	/** 仓库名称 */
	wareName?: string;
}

/** OrderBizExtDTO */
export interface OrderBizExtDTO {
	/** 包邮金额 */
	freeShippingAmount?: number;
	/** 是否新用户 */
	newUserFlag?: boolean;
	/** 新用户包邮门槛 */
	newUserFreeShippingAmount?: number;
	/** 原运费金额 */
	originalShippingAmount?: number;
}

/** OrderCalculatePriceReq */
export interface OrderCalculatePriceReq {
	/** 购物车id */
	cartIds?: number[];
	/** 订单收货地址 */
	orderReceivingAddress?: OrderReceivingAddressDTO;
	/** 商品信息(商详页下单) */
	productList?: ProductReq[];
}

/** OrderCalculatePriceResp */
export interface OrderCalculatePriceResp {
	/** 达到包邮金额 */
	achieveFreeShippingAmount?: number;
	/** 优惠金额 */
	discountAmount?: number;
	/** 异常商品sku */
	errorProductSku?: string;
	/** 包邮金额 */
	freeShippingAmount?: number;
	/** 国际运费金额 */
	internationalShippingAmount?: number;
	/** 是否新用户 */
	newUserFlag?: boolean;
	/** 应付金额 */
	payableAmount?: number;
	/** 商品列表 */
	productList?: OrderProductDTO[];
	/**
	 * 商品总数量
	 * @format int32
	 */
	productNum?: number;
	/** 商品总金额 */
	productTotalAmount?: number;
}

/** OrderCancelPopupsConfirmReq */
export interface OrderCancelPopupsConfirmReq {
	/**
	 * 确认类型 1 立即查看 2 稍后查看
	 * @format int32
	 */
	confirmType?: number;
}

/** OrderCancelPopupsResp */
export interface OrderCancelPopupsResp {
	/** 取消原因 */
	cancelReason?: string;
	/** 取消订单列表 */
	orderList?: OrderDTO[];
}

/** OrderCancelReq */
export interface OrderCancelReq {
	/** 取消原因 */
	cancelReason?: string;
	/** 代采订单号 */
	orderNo?: string;
	/** 代采子订单id */
	platformOrderItemId?: string;
	/** productSku */
	productSku?: string;
}

/** OrderCountResp */
export interface OrderCountResp {
	/**
	 * 已发货数量
	 * @format int32
	 */
	deliveredCount?: number;
	/**
	 * 待发货数量
	 * @format int32
	 */
	waitDeliverCount?: number;
	/**
	 * 待支付数量
	 * @format int32
	 */
	waitPayCount?: number;
}

/** OrderCreateReq */
export interface OrderCreateReq {
	/** 购物车id */
	cartIds?: number[];
	/** 客户备注 */
	customerRemark?: string;
	/** 订单收货地址 */
	orderReceivingAddress?: OrderReceivingAddressDTO;
	/** 下单人地址 */
	placeOrderAddress?: PlaceOrderAddressDTO;
	/** 商品信息(商详页下单) */
	productList?: ProductReq[];
	/**
	 * 渠道来源 1 pc客户端 2 h5
	 * @format int32
	 */
	sourceChannel?: number;
}

/** OrderCreateResp */
export interface OrderCreateResp {
	/** 异常商品sku */
	errorProductSku?: string;
	/** 订单号 */
	orderNo?: string;
	/** 应付总金额 */
	payableAmount?: number;
}

/** OrderDTO */
export interface OrderDTO {
	/** 实付总金额(支付回调) */
	actualAmount?: number;
	/**
	 * 代采店铺id
	 * @format int64
	 */
	agencyPurchaseShopId?: number;
	/** 扩展字段 */
	bizExt?: OrderBizExtDTO;
	/**
	 * 取消时间
	 * @format date-time
	 */
	cancelTime?: string;
	/** 关闭原因 */
	closeReason?: string;
	/**
	 * 关闭时间
	 * @format date-time
	 */
	closeTime?: string;
	/**
	 * 完成时间
	 * @format date-time
	 */
	completeTime?: string;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createdTime?: string;
	/**
	 * 客户id
	 * @format int64
	 */
	customerId?: number;
	/** 客户名称 */
	customerName?: string;
	/** 用户备注 */
	customerRemark?: string;
	/**
	 * 直采店铺id
	 * @format int64
	 */
	customerShopId?: number;
	/**
	 * 发货时间
	 * @format date-time
	 */
	deliverTime?: string;
	/** 优惠金额 */
	discountAmount?: number;
	/** 汇率快照 */
	exchangeRateSnapshot?: number;
	/** 包邮金额 */
	freeShippingAmount?: number;
	/** @format int64 */
	id?: number;
	/** 国际运费金额 */
	internationalShippingAmount?: number;
	/** 子订单列表 */
	orderItemList?: OrderItemDTO[];
	/** 主订单编号 */
	orderNo?: string;
	/**
	 * 订单状态(0：待支付，1：已支付(待发货)，2：已发货，3：已取消，4：已关闭)
	 * @format int32
	 */
	orderStatus?: number;
	/** 订单类型：枚举值，b2b/d2c */
	orderType?: string;
	/** 外部主订单编号(代采主订单编号) */
	outOrderNo?: string;
	/** 支付单信息 */
	payDetail?: string;
	/**
	 * 支付时间
	 * @format date-time
	 */
	payTime?: string;
	/** 支付类型(WALLET 钱包支付；PAYPAL paypal支付；PAYPAL_CREDIT paypal信用卡) */
	payTypeCode?: string;
	/** 应付总金额(商品总金额+国际运费) */
	payableAmount?: number;
	/** 下代采订单失败原因 */
	performErrorMessage?: string;
	/**
	 * 履约状态 0 未履约 1 下代采单成功 2 下代采单失败
	 * @format int32
	 */
	performStatus?: number;
	/** 下单人姓名 */
	placeOrderName?: string;
	/**
	 * 商品总数量
	 * @format int32
	 */
	productNum?: number;
	/** 商品总金额-优惠金额 */
	productTotalAmount?: number;
	/** 收货人姓名 */
	receiveName?: string;
	/**
	 * 渠道来源 1 pc客户端 2 h5
	 * @format int32
	 */
	sourceChannel?: number;
	/** 站点代码(日本:JapanStation,韩国:KoreaStation,英国:UkStation) */
	stationCode?: string;
	/**
	 * 统一用户id
	 * @format int64
	 */
	superCustomerId?: number;
	/**
	 * 超时时间
	 * @format date-time
	 */
	timeoutTime?: string;
	/**
	 * 交易类型(1:普通)
	 * @format int32
	 */
	tradeType?: number;
	/**
	 * 更新时间
	 * @format date-time
	 */
	updatedTime?: string;
	/** 仓库编码 */
	wareCode?: string;
	/** 仓库名称 */
	wareName?: string;
}

/** OrderDeliverReq */
export interface OrderDeliverReq {
	/** 是否全部发货 */
	allDeliverFlag?: boolean;
	/** 发货信息 */
	logisticsList?: OrderLogisticsReq[];
	/** 代采订单号 */
	orderNo?: string;
	/** 代采子订单id */
	platformOrderItemId?: string;
	/** productSku */
	productSku?: string;
}

/** OrderDetailAdminResp */
export interface OrderDetailAdminResp {
	/** 实付总金额(支付回调) */
	actualAmount?: number;
	/**
	 * 代采店铺id
	 * @format int64
	 */
	agencyPurchaseShopId?: number;
	/** 代采店铺名称 */
	agencyPurchaseShopName?: string;
	/** 扩展字段 */
	bizExt?: OrderBizExtDTO;
	/**
	 * 取消时间
	 * @format date-time
	 */
	cancelTime?: string;
	/** 关闭原因 */
	closeReason?: string;
	/**
	 * 关闭时间
	 * @format date-time
	 */
	closeTime?: string;
	/**
	 * 完成时间
	 * @format date-time
	 */
	completeTime?: string;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createdTime?: string;
	/**
	 * 客户id
	 * @format int64
	 */
	customerId?: number;
	/** 客户名称 */
	customerName?: string;
	/** 用户备注 */
	customerRemark?: string;
	/**
	 * 直采店铺id
	 * @format int64
	 */
	customerShopId?: number;
	/**
	 * 发货时间
	 * @format date-time
	 */
	deliverTime?: string;
	/** 优惠金额 */
	discountAmount?: number;
	/** 汇率快照 */
	exchangeRateSnapshot?: number;
	/** 包邮金额 */
	freeShippingAmount?: number;
	/** @format int64 */
	id?: number;
	/** 国际运费金额 */
	internationalShippingAmount?: number;
	/** 订单物流信息 */
	logisticsList?: OrderLogisticsDTO[];
	/** 子订单列表 */
	orderItemList?: OrderItemDTO[];
	/** 子订单列表 */
	orderItemRespList?: OrderItemAdminSearchResp[];
	/** 主订单编号 */
	orderNo?: string;
	/**
	 * 订单状态(0：待支付，1：已支付(待发货)，2：已发货，3：已取消，4：已关闭)
	 * @format int32
	 */
	orderStatus?: number;
	/** 订单类型：枚举值，b2b/d2c */
	orderType?: string;
	/** 外部主订单编号(代采主订单编号) */
	outOrderNo?: string;
	/** 支付单信息 */
	payDetail?: string;
	/** 支付单信息 */
	payDetailDto?: PayDetailDTO;
	/**
	 * 支付时间
	 * @format date-time
	 */
	payTime?: string;
	/** 支付类型(WALLET 钱包支付；PAYPAL paypal支付；PAYPAL_CREDIT paypal信用卡) */
	payTypeCode?: string;
	/** 应付总金额(商品总金额+国际运费) */
	payableAmount?: number;
	/** 下代采订单失败原因 */
	performErrorMessage?: string;
	/**
	 * 履约状态 0 未履约 1 下代采单成功 2 下代采单失败
	 * @format int32
	 */
	performStatus?: number;
	/** 下单人地址 */
	placeOrderAddress?: PlaceOrderAddressDTO;
	/** 下单人姓名 */
	placeOrderName?: string;
	/**
	 * 商品总数量
	 * @format int32
	 */
	productNum?: number;
	/** 商品总金额-优惠金额 */
	productTotalAmount?: number;
	/** 收货人姓名 */
	receiveName?: string;
	/** 订单收货地址 */
	receivingAddress?: OrderReceivingAddressDTO;
	/**
	 * 渠道来源 1 pc客户端 2 h5
	 * @format int32
	 */
	sourceChannel?: number;
	/** 站点代码(日本:JapanStation,韩国:KoreaStation,英国:UkStation) */
	stationCode?: string;
	/**
	 * 统一用户id
	 * @format int64
	 */
	superCustomerId?: number;
	/**
	 * 超时时间
	 * @format date-time
	 */
	timeoutTime?: string;
	/**
	 * 交易类型(1:普通)
	 * @format int32
	 */
	tradeType?: number;
	/**
	 * 更新时间
	 * @format date-time
	 */
	updatedTime?: string;
	/** 仓库编码 */
	wareCode?: string;
	/** 仓库名称 */
	wareName?: string;
}

/** OrderDetailAdminSearchReq */
export interface OrderDetailAdminSearchReq {
	/** 订单号 */
	orderNo?: string;
}

/** OrderDetailDTO */
export interface OrderDetailDTO {
	/** 实付总金额(支付回调) */
	actualAmount?: number;
	/**
	 * 代采店铺id
	 * @format int64
	 */
	agencyPurchaseShopId?: number;
	/** 扩展字段 */
	bizExt?: OrderBizExtDTO;
	/**
	 * 取消时间
	 * @format date-time
	 */
	cancelTime?: string;
	/** 关闭原因 */
	closeReason?: string;
	/**
	 * 关闭时间
	 * @format date-time
	 */
	closeTime?: string;
	/**
	 * 完成时间
	 * @format date-time
	 */
	completeTime?: string;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createdTime?: string;
	/**
	 * 客户id
	 * @format int64
	 */
	customerId?: number;
	/** 客户名称 */
	customerName?: string;
	/** 用户备注 */
	customerRemark?: string;
	/**
	 * 直采店铺id
	 * @format int64
	 */
	customerShopId?: number;
	/**
	 * 发货时间
	 * @format date-time
	 */
	deliverTime?: string;
	/** 优惠金额 */
	discountAmount?: number;
	/** 汇率快照 */
	exchangeRateSnapshot?: number;
	/** 包邮金额 */
	freeShippingAmount?: number;
	/** @format int64 */
	id?: number;
	/** 国际运费金额 */
	internationalShippingAmount?: number;
	/** 订单物流信息 */
	logisticsList?: OrderLogisticsDTO[];
	/** 子订单列表 */
	orderItemList?: OrderItemDTO[];
	/** 主订单编号 */
	orderNo?: string;
	/**
	 * 订单状态(0：待支付，1：已支付(待发货)，2：已发货，3：已取消，4：已关闭)
	 * @format int32
	 */
	orderStatus?: number;
	/** 订单类型：枚举值，b2b/d2c */
	orderType?: string;
	/** 外部主订单编号(代采主订单编号) */
	outOrderNo?: string;
	/** 支付单信息 */
	payDetail?: string;
	/** 支付单信息 */
	payDetailDto?: PayDetailDTO;
	/**
	 * 支付时间
	 * @format date-time
	 */
	payTime?: string;
	/** 支付类型(WALLET 钱包支付；PAYPAL paypal支付；PAYPAL_CREDIT paypal信用卡) */
	payTypeCode?: string;
	/** 应付总金额(商品总金额+国际运费) */
	payableAmount?: number;
	/** 下代采订单失败原因 */
	performErrorMessage?: string;
	/**
	 * 履约状态 0 未履约 1 下代采单成功 2 下代采单失败
	 * @format int32
	 */
	performStatus?: number;
	/** 下单人地址 */
	placeOrderAddress?: PlaceOrderAddressDTO;
	/** 下单人姓名 */
	placeOrderName?: string;
	/**
	 * 商品总数量
	 * @format int32
	 */
	productNum?: number;
	/** 商品总金额-优惠金额 */
	productTotalAmount?: number;
	/** 收货人姓名 */
	receiveName?: string;
	/** 订单收货地址 */
	receivingAddress?: OrderReceivingAddressDTO;
	/**
	 * 渠道来源 1 pc客户端 2 h5
	 * @format int32
	 */
	sourceChannel?: number;
	/** 站点代码(日本:JapanStation,韩国:KoreaStation,英国:UkStation) */
	stationCode?: string;
	/**
	 * 统一用户id
	 * @format int64
	 */
	superCustomerId?: number;
	/**
	 * 超时时间
	 * @format date-time
	 */
	timeoutTime?: string;
	/**
	 * 交易类型(1:普通)
	 * @format int32
	 */
	tradeType?: number;
	/**
	 * 更新时间
	 * @format date-time
	 */
	updatedTime?: string;
	/** 仓库编码 */
	wareCode?: string;
	/** 仓库名称 */
	wareName?: string;
}

/** OrderExportResp */
export interface OrderExportResp {
	/**
	 * 导出任务id
	 * @format int64
	 */
	taskId?: number;
}

/** OrderItemAdminSearchResp */
export interface OrderItemAdminSearchResp {
	/** 实付金额 */
	actualAmount?: number;
	/** 扩展字段 */
	bizExt?: OrderItemBizExtDTO;
	/** 取消原因 */
	cancelReason?: string;
	/**
	 * 取消时间
	 * @format date-time
	 */
	cancelTime?: string;
	/** 商品后台一级类目 */
	cateCodeLevel1?: string;
	/** 商品后台二级类目 */
	cateCodeLevel2?: string;
	/** 商品后台三级类目 */
	cateCodeLevel3?: string;
	/** 后台类目 */
	cateName?: string;
	cateNameLevel1?: string;
	cateNameLevel2?: string;
	cateNameLevel3?: string;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createdTime?: string;
	/**
	 * 发货时间
	 * @format date-time
	 */
	deliverTime?: string;
	/** 优惠金额 */
	discountAmount?: number;
	/** 汇率 */
	exchangeRateSnapshot?: number;
	/** 包邮金额 */
	freeShippingAmount?: number;
	/** @format int64 */
	id?: number;
	/**
	 * 起订量
	 * @format int32
	 */
	minimumQuantity?: number;
	/**
	 * 是否一元购商品 0 否 1 是
	 * @format int32
	 */
	oneBuyFlag?: number;
	/** 子订单编号 */
	orderItemNo?: string;
	/**
	 * 子订单状态(0:待支付，1:待发货，2：已发货，3：已取消，4：已关闭)
	 * @format int32
	 */
	orderItemStatus?: number;
	/** 主订单编号 */
	orderNo?: string;
	/** 外部子订单编号(代采子订单编号) */
	outOrderItemNo?: string;
	/** 应付金额(商品总金额) */
	payableAmount?: number;
	/** 平台商品SPU */
	productCode?: string;
	/** 直采商详链接 */
	productDetailUrl?: string;
	/** 商品图片 */
	productImg?: string;
	/** 商品名称 */
	productName?: string;
	/** 商品番号 */
	productNo?: string;
	/** 商品金额 */
	productPrice?: number;
	/** 商品规格 */
	productPropertiesName?: string;
	/**
	 * 下单数量
	 * @format int32
	 */
	productQuantity?: number;
	/** 平台商品SKU */
	productSku?: string;
	/** 商品快照,json格式 */
	productSnapshot?: OrderProductDTO;
	/** 商品总金额(数量*单价-优惠金额) */
	productTotalAmount?: number;
	/**
	 * 商品售卖状态 1 正常 2 售罄 3 下架
	 * @format int32
	 */
	sellStatus?: number;
	/** 站点代码(日本:JapanStation,韩国:KoreaStation,英国:UkStation) */
	stationCode?: string;
	/** 1688连接 */
	thirdProductDetailUrl?: string;
	/**
	 * 更新时间
	 * @format date-time
	 */
	updatedTime?: string;
}

/** OrderItemBizExtDTO */
export interface OrderItemBizExtDTO {
	/** 包邮金额 */
	freeShippingAmount?: number;
}

/** OrderItemDTO */
export interface OrderItemDTO {
	/** 实付金额 */
	actualAmount?: number;
	/** 扩展字段 */
	bizExt?: OrderItemBizExtDTO;
	/** 取消原因 */
	cancelReason?: string;
	/**
	 * 取消时间
	 * @format date-time
	 */
	cancelTime?: string;
	/** 商品后台一级类目 */
	cateCodeLevel1?: string;
	/** 商品后台二级类目 */
	cateCodeLevel2?: string;
	/** 商品后台三级类目 */
	cateCodeLevel3?: string;
	cateNameLevel1?: string;
	cateNameLevel2?: string;
	cateNameLevel3?: string;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createdTime?: string;
	/**
	 * 发货时间
	 * @format date-time
	 */
	deliverTime?: string;
	/** 优惠金额 */
	discountAmount?: number;
	/** 汇率 */
	exchangeRateSnapshot?: number;
	/** 包邮金额 */
	freeShippingAmount?: number;
	/** @format int64 */
	id?: number;
	/**
	 * 是否一元购商品 0 否 1 是
	 * @format int32
	 */
	oneBuyFlag?: number;
	/** 子订单编号 */
	orderItemNo?: string;
	/**
	 * 子订单状态(0:待支付，1:待发货，2：已发货，3：已取消，4：已关闭)
	 * @format int32
	 */
	orderItemStatus?: number;
	/** 主订单编号 */
	orderNo?: string;
	/** 外部子订单编号(代采子订单编号) */
	outOrderItemNo?: string;
	/** 应付金额(商品总金额) */
	payableAmount?: number;
	/** 平台商品SPU */
	productCode?: string;
	/** 商品图片 */
	productImg?: string;
	/** 商品名称 */
	productName?: string;
	/** 商品金额 */
	productPrice?: number;
	/** 商品规格 */
	productPropertiesName?: string;
	/**
	 * 下单数量
	 * @format int32
	 */
	productQuantity?: number;
	/** 平台商品SKU */
	productSku?: string;
	/** 商品快照,json格式 */
	productSnapshot?: OrderProductDTO;
	/** 商品总金额(数量*单价-优惠金额) */
	productTotalAmount?: number;
	/**
	 * 商品售卖状态 1 正常 2 售罄 3 下架
	 * @format int32
	 */
	sellStatus?: number;
	/** 站点代码(日本:JapanStation,韩国:KoreaStation,英国:UkStation) */
	stationCode?: string;
	/**
	 * 更新时间
	 * @format date-time
	 */
	updatedTime?: string;
}

/** OrderLogisticsDTO */
export interface OrderLogisticsDTO {
	/** 扩展字段 */
	bizExt?: string;
	/**
	 * 生成时间
	 * @format date-time
	 */
	createDeliveryTaskTime?: string;
	/**
	 * 发货状态(0:待处理 1:拣货中 2:打标中 3:打包中 4:打包完成 5:已发货 10:已取消)
	 * @format int32
	 */
	deliveryStatus?: number;
	/** 发货编号 */
	deliveryTaskCode?: string;
	/**
	 * 发货时间
	 * @format date-time
	 */
	deliveryTime?: string;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/** 国际运单号 */
	logisticsCode?: string;
	/** 订单号 */
	orderNo?: string;
	/** 发货商品信息 */
	productList?: OrderLogisticsProductDTO[];
	/** 站点代码(日本:JapanStation,韩国:KoreaStation,英国:UkStation) */
	stationCode?: string;
	/** 实际发货国际物流 */
	wmsDistribution?: string;
	/** 实际国内货代公司 */
	wmsForwarder?: string;
	/** 运输方式 */
	wmsShippingType?: string;
}

/** OrderLogisticsProductDTO */
export interface OrderLogisticsProductDTO {
	/** 扩展字段 */
	bizExt?: string;
	/**
	 * 发货数量
	 * @format int32
	 */
	deliveryNum?: number;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/**
	 * 订单物流信息id
	 * @format int64
	 */
	orderLogisticsId?: number;
	/** 平台商品SPU */
	productCode?: string;
	/** 商品图片 */
	productImg?: string;
	/** 平台商品名称 */
	productName?: string;
	/** 商品单价 */
	productPrice?: number;
	/** 商品规格 */
	productPropertiesName?: string;
	/** 平台商品SKU */
	productSku?: string;
	/** 合计金额 */
	productTotalAmount?: number;
	/** 站点代码(日本:JapanStation,韩国:KoreaStation,英国:UkStation) */
	stationCode?: string;
}

/** OrderLogisticsProductReq */
export interface OrderLogisticsProductReq {
	/**
	 * 发货数量
	 * @format int32
	 */
	deliveryNum?: number;
	/** 平台商品SPU */
	productCode?: string;
	/** 平台商品SKU */
	productSku?: string;
}

/** OrderLogisticsReq */
export interface OrderLogisticsReq {
	/**
	 * 生成时间
	 * @format date-time
	 */
	createDeliveryTaskTime?: string;
	/**
	 * 发货状态(0:待处理 1:拣货中 2:打标中 3:打包中 4:打包完成 5:已发货 10:已取消)
	 * @format int32
	 */
	deliveryStatus?: number;
	/** 发货编号 */
	deliveryTaskCode?: string;
	/**
	 * 发货时间
	 * @format date-time
	 */
	deliveryTime?: string;
	/** 国际运单号 */
	logisticsCodes?: string[];
	/** 发货商品信息 */
	productList?: OrderLogisticsProductReq[];
	/** 实际发货国际物流 */
	wmsDistribution?: string;
	/** 实际国内货代公司 */
	wmsForwarder?: string;
	/** 运输方式 */
	wmsShippingType?: string;
}

/** OrderProductDTO */
export interface OrderProductDTO {
	/**
	 * 是否到达起订量
	 * @format int32
	 */
	arrivalMinimumQuantityFlag?: number;
	/**
	 * 购物车id
	 * @format int64
	 */
	cartId?: number;
	/** 商品后台一级类目 */
	cateCodeLevel1?: string;
	/** 商品后台二级类目 */
	cateCodeLevel2?: string;
	/** 商品后台三级类目 */
	cateCodeLevel3?: string;
	cateNameLevel1?: string;
	cateNameLevel2?: string;
	cateNameLevel3?: string;
	/** 商品优惠总价 */
	discountTotalAmount?: number;
	/** 包邮金额 */
	freeShippingAmount?: number;
	/**
	 * 起订量
	 * @format int32
	 */
	minimumQuantity?: number;
	/** 是否新用户 */
	newUserFlag?: boolean;
	/**
	 * 是否一元购商品 0 否 1 是
	 * @format int32
	 */
	oneBuyFlag?: number;
	/** 商品spu */
	productCode?: string;
	/** 直采商详链接 */
	productDetailUrl?: string;
	/** 优惠信息 */
	productDiscount?: ProductDiscountDTO;
	/** 商品图片 */
	productImg?: string;
	/** 商品名称 */
	productName?: string;
	/** 商品番号 */
	productNo?: string;
	/** 商品价格 */
	productPrice?: number;
	/** 商品规格名称 */
	productPropertiesName?: string;
	/**
	 * 数量
	 * @format int32
	 */
	productQuantity?: number;
	/** 商品sku */
	productSku?: string;
	/**
	 * 商品库存
	 * @format int32
	 */
	productStockQuantity?: number;
	/** 商品总价 */
	productTotalAmount?: number;
	/** 商品属性 */
	productType?: string;
	/**
	 * 商品重量
	 * @format double
	 */
	productWeight?: number;
	/**
	 * 是否选择 0 未选 1 已选
	 * @format int32
	 */
	selectedFlag?: number;
	/**
	 * 商品售卖状态 1 正常 2 售罄 3 下架
	 * @format int32
	 */
	sellStatus?: number;
	/**
	 * spu数量
	 * @format int32
	 */
	spuQuantity?: number;
	/** 1688连接 */
	thirdProductDetailUrl?: string;
	transportAttr?: string;
	transportAttrCode?: string;
}

/** OrderReceivingAddressDTO */
export interface OrderReceivingAddressDTO {
	/** 地址 */
	address?: string;
	/**
	 * 市id
	 * @format int64
	 */
	cityId?: number;
	/** 市(日文) */
	cityName?: string;
	/** 国家代码 */
	countryCode?: string;
	/**
	 * 国家id
	 * @format int64
	 */
	countryId?: number;
	/** 国家(日文) */
	countryName?: string;
	/** 邮箱 */
	email?: string;
	/** 订单号 */
	orderNo?: string;
	/** 邮政编码 */
	postalCode?: string;
	/**
	 * 省id
	 * @format int64
	 */
	provinceId?: number;
	/** 省(日文) */
	provinceName?: string;
	/** 收货人名称 */
	receiveName?: string;
	/** 收货人电话或者手机 */
	receiveTel?: string;
}

/** OrderSearchDetailReq */
export interface OrderSearchDetailReq {
	/** 订单号 */
	orderNo?: string;
}

/** OrderSearchReq */
export interface OrderSearchReq {
	/** @format int64 */
	customerId?: number;
	dataSource?: string;
	/**
	 * 发货时间结束
	 * @format date-time
	 */
	deliverTimeEnd?: string;
	/**
	 * 发货时间开始
	 * @format date-time
	 */
	deliverTimeStart?: string;
	/**
	 * 订单创建时间结束
	 * @format date-time
	 */
	orderCreateTimeEnd?: string;
	/**
	 * 订单创建时间开始
	 * @format date-time
	 */
	orderCreateTimeStart?: string;
	/**
	 * 子订单状态(0:待支付，1:待发货，2：已发货，3：已取消，4：已关闭)
	 * @format int32
	 */
	orderItemStatus?: number;
	/** 订单号 */
	orderNos?: string[];
	/** 主订单状态(0:待支付，1:待发货，2：已发货，3：已取消，4：已关闭) */
	orderStatusList?: number[];
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 商品名称 */
	productName?: string;
	/** sku */
	productSkus?: string[];
	/** 收货人名称 */
	receiveName?: string;
	/** orderNo/商品名称/sku */
	searchKey?: string;
	/** @format int32 */
	startIndex?: number;
}

/** PayDetailDTO */
export interface PayDetailDTO {
	/** 业务单号 直采商城订单-订单号 其他待定 */
	bizNo?: string;
	/** 业务类型 EASY_ORDER:直采商城订单 */
	bizType?: string;
	/** 币种 */
	currency?: string;
	/**
	 * 客户id
	 * @format int64
	 */
	customerId?: number;
	/** 扩展字段 */
	ext?: string;
	/** 已支付金额 */
	paidAmount?: number;
	/** 支付单号 */
	payNo?: string;
	/**
	 * 支付成功时间
	 * @format date-time
	 */
	paySuccessTime?: string;
	/** 支付方式 */
	payTypeInfo?: PayTypeInfoDTO[];
	/** 站点 */
	stationCode?: string;
	/** 状态 SUCCESS:成功 */
	status?: string;
	/** 交易金额(请求支付金额) */
	transAmount?: number;
	/**
	 * 请求时间
	 * @format date-time
	 */
	transTime?: string;
}

/** PayTypeInfoDTO */
export interface PayTypeInfoDTO {
	/** 支付方式下支付金额 */
	payAmount?: string;
	/** 支付流水号 */
	payChannelNo?: string;
	/** 支付方式 WALLET:钱包支付 PAYPAL:paypal支付 PAYPAL_CREDIT:paypal信用卡 */
	payType?: string;
}

/** PlaceOrderAddressDTO */
export interface PlaceOrderAddressDTO {
	/** 地址 */
	address?: string;
	/** 地址(英文) */
	addressEn?: string;
	/**
	 * 市id
	 * @format int64
	 */
	cityId?: number;
	/** 市(日文) */
	cityName?: string;
	/** 市(英文) */
	cityNameEn?: string;
	/**
	 * 通关类型 1 企业 2 用户
	 * @format int32
	 */
	clearanceType?: number;
	/** 会社编号 */
	companyNo?: string;
	/** 国家代码 */
	countryCode?: string;
	/**
	 * 国家id
	 * @format int64
	 */
	countryId?: number;
	/** 国家(日文) */
	countryName?: string;
	/** 邮箱 */
	email?: string;
	/** 订单号 */
	orderNo?: string;
	/** 公司名 */
	placeOrderCompanyName?: string;
	/** 公司名(英文) */
	placeOrderCompanyNameEn?: string;
	/** 姓名 */
	placeOrderName?: string;
	/** 姓名(英文) */
	placeOrderNameEn?: string;
	/** 邮政编码 */
	postalCode?: string;
	/**
	 * 省id
	 * @format int64
	 */
	provinceId?: number;
	/** 省(日文) */
	provinceName?: string;
	/** 省(英文) */
	provinceNameEn?: string;
	/** 联系电话 */
	tel?: string;
}

/** ProductDiscountDTO */
export interface ProductDiscountDTO {
	/** 活动编码 */
	activityCode?: string;
	/** 活动名称 */
	activityName?: string;
	/**
	 * 活动类型 0:限时折扣 1:一口价
	 * @format int32
	 */
	activityType?: number;
	/** 优惠金额 */
	discountAmt?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	endTime?: string;
	/**
	 * 价格类型 0:固定价 1:溢价率 2:折扣率
	 * @format int32
	 */
	priceType?: number;
	/** 价格值 */
	priceValue?: number;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/**
	 * 活动状态 0:草稿 1:未开始 2:活动中 3:已结束
	 * @format int32
	 */
	status?: number;
}

/** ProductReq */
export interface ProductReq {
	/** 活动code */
	activityCode?: string;
	/**
	 * 购物车id
	 * @format int64
	 */
	cartId?: number;
	/** 平台商品SPU */
	productCode?: string;
	/** 平台商品SKU */
	productSku?: string;
	/**
	 * 商品数量
	 * @format int32
	 */
	quantity?: number;
}

/** ShopResp */
export interface ShopResp {
	/**
	 * 店铺名称
	 * @format int64
	 */
	shopId?: number;
	/** 店铺名称 */
	shopName?: string;
}

/** UpdateCartSelectedReq */
export interface UpdateCartSelectedReq {
	/** 购物车ids */
	ids?: number[];
	/**
	 * 是否选择 0 未选 1 已选
	 * @format int32
	 */
	selectedFlag?: number;
}

/** UserDefaultFreeShippingResp */
export interface UserDefaultFreeShippingResp {
	/** 适用地址 */
	address?: string;
	/** 包邮金额 */
	freeShippingAmount?: number;
	/** 是否新用户 */
	newUserFlag?: boolean;
}

/** UserOrderCloseReq */
export interface UserOrderCloseReq {
	/** 订单号 */
	orderNo?: string;
}

/** UserPlaceOrderAddressDTO */
export interface UserPlaceOrderAddressDTO {
	/** 地址 */
	address?: string;
	/** 地址(英文) */
	addressEn?: string;
	/**
	 * 市id
	 * @format int64
	 */
	cityId?: number;
	/** 市(日文) */
	cityName?: string;
	/** 市(英文) */
	cityNameEn?: string;
	/**
	 * 通关类型 1 企业 2 用户
	 * @format int32
	 */
	clearanceType?: number;
	/** 会社编号 */
	companyNo?: string;
	/** 国家代码 */
	countryCode?: string;
	/**
	 * 国家id
	 * @format int64
	 */
	countryId?: number;
	/** 国家(日文) */
	countryName?: string;
	/**
	 * id
	 * @format int64
	 */
	customerId?: number;
	/**
	 * 是否默认 0 否 1 是
	 * @format int32
	 */
	defaultFlag?: number;
	/** 邮箱 */
	email?: string;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/** 公司名 */
	placeOrderCompanyName?: string;
	/** 公司名(英文) */
	placeOrderCompanyNameEn?: string;
	/** 姓名 */
	placeOrderName?: string;
	/** 姓名(英文) */
	placeOrderNameEn?: string;
	/** 邮政编码 */
	postalCode?: string;
	/**
	 * 省id
	 * @format int64
	 */
	provinceId?: number;
	/** 省(日文) */
	provinceName?: string;
	/** 省(英文) */
	provinceNameEn?: string;
	stationCode?: string;
	/** 联系电话 */
	tel?: string;
}

/** UserReceivingAddressDTO */
export interface UserReceivingAddressDTO {
	/** 地址 */
	address?: string;
	/**
	 * 市
	 * @format int64
	 */
	cityId?: number;
	/** 市(日文) */
	cityName?: string;
	/** 国家代码 */
	countryCode?: string;
	/**
	 * 国家
	 * @format int64
	 */
	countryId?: number;
	/** 国家(日文) */
	countryName?: string;
	/**
	 * 客户id
	 * @format int64
	 */
	customerId?: number;
	/**
	 * 是否默认 0 否 1 是
	 * @format int32
	 */
	defaultFlag?: number;
	/** 邮箱 */
	email?: string;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/** 邮政编码 */
	postalCode?: string;
	/**
	 * 省
	 * @format int64
	 */
	provinceId?: number;
	/** 省(日文) */
	provinceName?: string;
	/** 收货人名称 */
	receiveName?: string;
	/** 收货人电话或者手机 */
	receiveTel?: string;
	stationCode?: string;
}

/** BizResponse«CartAddResp» */
export interface BizResponseCartAddResp {
	code?: string;
	data?: CartAddResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«CartCalculatePriceResp» */
export interface BizResponseCartCalculatePriceResp {
	code?: string;
	data?: CartCalculatePriceResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«OrderItemAdminSearchResp»» */
export interface BizResponseListOrderItemAdminSearchResp {
	code?: string;
	data?: OrderItemAdminSearchResp[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«OrderLogisticsDTO»» */
export interface BizResponseListOrderLogisticsDTO {
	code?: string;
	data?: OrderLogisticsDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«OrderProductDTO»» */
export interface BizResponseListOrderProductDTO {
	code?: string;
	data?: OrderProductDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«ShopResp»» */
export interface BizResponseListShopResp {
	code?: string;
	data?: ShopResp[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«UserPlaceOrderAddressDTO»» */
export interface BizResponseListUserPlaceOrderAddressDTO {
	code?: string;
	data?: UserPlaceOrderAddressDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«UserReceivingAddressDTO»» */
export interface BizResponseListUserReceivingAddressDTO {
	code?: string;
	data?: UserReceivingAddressDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«OrderCalculatePriceResp» */
export interface BizResponseOrderCalculatePriceResp {
	code?: string;
	data?: OrderCalculatePriceResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«OrderCancelPopupsResp» */
export interface BizResponseOrderCancelPopupsResp {
	code?: string;
	data?: OrderCancelPopupsResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«OrderCountResp» */
export interface BizResponseOrderCountResp {
	code?: string;
	data?: OrderCountResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«OrderCreateResp» */
export interface BizResponseOrderCreateResp {
	code?: string;
	data?: OrderCreateResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«OrderDTO» */
export interface BizResponseOrderDTO {
	code?: string;
	data?: OrderDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«OrderDetailAdminResp» */
export interface BizResponseOrderDetailAdminResp {
	code?: string;
	data?: OrderDetailAdminResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«OrderDetailDTO» */
export interface BizResponseOrderDetailDTO {
	code?: string;
	data?: OrderDetailDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«OrderExportResp» */
export interface BizResponseOrderExportResp {
	code?: string;
	data?: OrderExportResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«OrderAdminSearchResp»» */
export interface BizResponsePageResultOrderAdminSearchResp {
	code?: string;
	data?: PageResultOrderAdminSearchResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«OrderDTO»» */
export interface BizResponsePageResultOrderDTO {
	code?: string;
	data?: PageResultOrderDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PlaceOrderAddressDTO» */
export interface BizResponsePlaceOrderAddressDTO {
	code?: string;
	data?: PlaceOrderAddressDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«UserDefaultFreeShippingResp» */
export interface BizResponseUserDefaultFreeShippingResp {
	code?: string;
	data?: UserDefaultFreeShippingResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«UserPlaceOrderAddressDTO» */
export interface BizResponseUserPlaceOrderAddressDTO {
	code?: string;
	data?: UserPlaceOrderAddressDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«UserReceivingAddressDTO» */
export interface BizResponseUserReceivingAddressDTO {
	code?: string;
	data?: UserReceivingAddressDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Void» */
export interface BizResponseVoid {
	code?: string;
	msg?: string;
	success?: boolean;
}

/** BizResponse«boolean» */
export interface BizResponseBoolean {
	code?: string;
	data?: boolean;
	msg?: string;
	success?: boolean;
}

/** BizResponse«object» */
export interface BizResponseObject {
	code?: string;
	data?: object;
	msg?: string;
	success?: boolean;
}

/** BizResponse«string» */
export interface BizResponseString {
	code?: string;
	data?: string;
	msg?: string;
	success?: boolean;
}

/** PageResult«OrderAdminSearchResp» */
export interface PageResultOrderAdminSearchResp {
	/** @format int64 */
	current?: number;
	records?: OrderAdminSearchResp[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** PageResult«OrderDTO» */
export interface PageResultOrderDTO {
	/** @format int64 */
	current?: number;
	records?: OrderDTO[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

import axios, { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseType;
	/** request body */
	body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
	securityWorker?: (
		securityData: SecurityDataType | null
	) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
	secure?: boolean;
	format?: ResponseType;
}

export enum ContentType {
	Json = 'application/json',
	FormData = 'multipart/form-data',
	UrlEncoded = 'application/x-www-form-urlencoded',
	Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
	public instance: AxiosInstance;
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
	private secure?: boolean;
	private format?: ResponseType;

	constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
		this.instance = axios.create({
			...axiosConfig,
			baseURL: axiosConfig.baseURL || '//master-gateway.theckb.com:443/easy/order'
		});
		this.secure = secure;
		this.format = format;
		this.securityWorker = securityWorker;
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
		const method = params1.method || (params2 && params2.method);

		return {
			...this.instance.defaults,
			...params1,
			...(params2 || {}),
			headers: {
				...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {})
			}
		};
	}

	protected stringifyFormItem(formItem: unknown) {
		if (typeof formItem === 'object' && formItem !== null) {
			return JSON.stringify(formItem);
		} else {
			return `${formItem}`;
		}
	}

	protected createFormData(input: Record<string, unknown>): FormData {
		return Object.keys(input || {}).reduce((formData, key) => {
			const property = input[key];
			const propertyContent: any[] = property instanceof Array ? property : [property];

			for (const formItem of propertyContent) {
				const isFileType = formItem instanceof Blob || formItem instanceof File;
				formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
			}

			return formData;
		}, new FormData());
	}

	public request = async <T = any, _E = any>({
		secure,
		path,
		type,
		query,
		format,
		body,
		...params
	}: FullRequestParams): Promise<T> => {
		const secureParams =
			((typeof secure === 'boolean' ? secure : this.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const responseFormat = format || this.format || undefined;

		if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
			body = this.createFormData(body as Record<string, unknown>);
		}

		if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
			body = JSON.stringify(body);
		}

		return this.instance
			.request({
				...requestParams,
				headers: {
					...(requestParams.headers || {}),
					...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
				},
				params: query,
				responseType: responseFormat,
				data: body,
				url: path
			})
			.then((response) => response.data);
	};
}

/**
 * @title API文档
 * @version v1.0.0
 * @baseUrl //master-gateway.theckb.com:443/easy/order
 * @contact 史尼芙 (https://www.taobaockb.com/)
 *
 * 史尼芙API文档
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
	probe = {
		/**
		 * No description
		 *
		 * @tags livens-probe-controller
		 * @name Test
		 * @summary 执行一次健康检查探针
		 * @request GET:/Probe/test
		 */
		test: (params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/Probe/test`,
				method: 'GET',
				...params
			})
	};
	cart = {
		/**
		 * No description
		 *
		 * @tags 购物车相关接口
		 * @name PostCart
		 * @summary 添加购物车
		 * @request POST:/cart/add
		 */
		postCart: (req: CartAddReq, params: RequestParams = {}) =>
			this.request<BizResponseCartAddResp, any>({
				path: `/cart/add`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 购物车相关接口
		 * @name CalculatePrice
		 * @summary 购物车算价
		 * @request POST:/cart/calculatePrice
		 */
		calculatePrice: (req: CartCalculatePriceReq, params: RequestParams = {}) =>
			this.request<BizResponseCartCalculatePriceResp, any>({
				path: `/cart/calculatePrice`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 购物车相关接口
		 * @name Delete
		 * @summary 删除购物车
		 * @request POST:/cart/delete
		 */
		delete: (req: CartDeleteReq, params: RequestParams = {}) =>
			this.request<BizResponseVoid, any>({
				path: `/cart/delete`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 购物车相关接口
		 * @name List
		 * @summary 购物车列表
		 * @request POST:/cart/list
		 */
		list: (params: RequestParams = {}) =>
			this.request<BizResponseListOrderProductDTO, any>({
				path: `/cart/list`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 购物车相关接口
		 * @name Update
		 * @summary 修改购物车
		 * @request POST:/cart/update
		 */
		update: (req: CartUpdateReq, params: RequestParams = {}) =>
			this.request<BizResponseVoid, any>({
				path: `/cart/update`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 购物车相关接口
		 * @name UpdateSelected
		 * @summary 修改购物车选中状态
		 * @request POST:/cart/updateSelected
		 */
		updateSelected: (req: UpdateCartSelectedReq, params: RequestParams = {}) =>
			this.request<BizResponseVoid, any>({
				path: `/cart/updateSelected`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			})
	};
	order = {
		/**
		 * No description
		 *
		 * @tags 订单交易相关接口
		 * @name CalculatePrice
		 * @summary 提单页算价
		 * @request POST:/order/calculatePrice
		 */
		calculatePrice: (req: OrderCalculatePriceReq, params: RequestParams = {}) =>
			this.request<BizResponseOrderCalculatePriceResp, any>({
				path: `/order/calculatePrice`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 订单交易相关接口
		 * @name Close
		 * @summary 关闭订单
		 * @request POST:/order/close
		 */
		close: (req: UserOrderCloseReq, params: RequestParams = {}) =>
			this.request<BizResponseVoid, any>({
				path: `/order/close`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 订单交易相关接口
		 * @name Create
		 * @summary 下单
		 * @request POST:/order/create
		 */
		create: (req: OrderCreateReq, params: RequestParams = {}) =>
			this.request<BizResponseOrderCreateResp, any>({
				path: `/order/create`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			})
	};
	orderSearch = {
		/**
		 * No description
		 *
		 * @tags 订单查询相关接口
		 * @name List
		 * @summary 查询订单列表
		 * @request POST:/orderSearch/list
		 */
		list: (req: OrderSearchReq, params: RequestParams = {}) =>
			this.request<BizResponsePageResultOrderDTO, any>({
				path: `/orderSearch/list`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 订单查询相关接口
		 * @name OrderCancelPopups
		 * @summary 订单取消弹窗
		 * @request POST:/orderSearch/orderCancelPopups
		 */
		orderCancelPopups: (params: RequestParams = {}) =>
			this.request<BizResponseOrderCancelPopupsResp, any>({
				path: `/orderSearch/orderCancelPopups`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 订单查询相关接口
		 * @name OrderCancelPopupsConfirm
		 * @summary 确认订单取消弹窗
		 * @request POST:/orderSearch/orderCancelPopupsConfirm
		 */
		orderCancelPopupsConfirm: (req: OrderCancelPopupsConfirmReq, params: RequestParams = {}) =>
			this.request<BizResponseVoid, any>({
				path: `/orderSearch/orderCancelPopupsConfirm`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 订单查询相关接口
		 * @name QueryDetailByOrderNo
		 * @summary 查询订单详情
		 * @request POST:/orderSearch/queryDetailByOrderNo
		 */
		queryDetailByOrderNo: (req: OrderSearchDetailReq, params: RequestParams = {}) =>
			this.request<BizResponseOrderDetailDTO, any>({
				path: `/orderSearch/queryDetailByOrderNo`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 订单查询相关接口
		 * @name QueryOrderCount
		 * @summary 查询工作台订单数量统计
		 * @request POST:/orderSearch/queryOrderCount
		 */
		queryOrderCount: (params: RequestParams = {}) =>
			this.request<BizResponseOrderCountResp, any>({
				path: `/orderSearch/queryOrderCount`,
				method: 'POST',
				type: ContentType.Json,
				...params
			})
	};
	userPlaceOrderAddress = {
		/**
		 * No description
		 *
		 * @tags 用户下单人相关接口
		 * @name Delete
		 * @summary 删除下单人地址
		 * @request POST:/userPlaceOrderAddress/delete
		 */
		delete: (req: UserPlaceOrderAddressDTO, params: RequestParams = {}) =>
			this.request<BizResponseVoid, any>({
				path: `/userPlaceOrderAddress/delete`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 用户下单人相关接口
		 * @name FindById
		 * @summary 根据id查询下单人信息
		 * @request POST:/userPlaceOrderAddress/findById
		 */
		findById: (req: UserPlaceOrderAddressDTO, params: RequestParams = {}) =>
			this.request<BizResponseUserPlaceOrderAddressDTO, any>({
				path: `/userPlaceOrderAddress/findById`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 用户下单人相关接口
		 * @name List
		 * @summary 查询下单人列表
		 * @request POST:/userPlaceOrderAddress/list
		 */
		list: (params: RequestParams = {}) =>
			this.request<BizResponseListUserPlaceOrderAddressDTO, any>({
				path: `/userPlaceOrderAddress/list`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 用户下单人相关接口
		 * @name Save
		 * @summary 新增/修改下单人地址
		 * @request POST:/userPlaceOrderAddress/save
		 */
		save: (req: UserPlaceOrderAddressDTO, params: RequestParams = {}) =>
			this.request<BizResponseVoid, any>({
				path: `/userPlaceOrderAddress/save`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			})
	};
	userReceivingAddress = {
		/**
		 * No description
		 *
		 * @tags 用户收货地址相关接口
		 * @name Delete
		 * @summary 删除收货人地址
		 * @request POST:/userReceivingAddress/delete
		 */
		delete: (req: UserReceivingAddressDTO, params: RequestParams = {}) =>
			this.request<BizResponseVoid, any>({
				path: `/userReceivingAddress/delete`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 用户收货地址相关接口
		 * @name FindById
		 * @summary 根据id查询收货人地址
		 * @request POST:/userReceivingAddress/findById
		 */
		findById: (req: UserReceivingAddressDTO, params: RequestParams = {}) =>
			this.request<BizResponseUserReceivingAddressDTO, any>({
				path: `/userReceivingAddress/findById`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 用户收货地址相关接口
		 * @name FindDefaultFreeShipping
		 * @summary 查询用户默认包邮金额
		 * @request POST:/userReceivingAddress/findDefaultFreeShipping
		 */
		findDefaultFreeShipping: (params: RequestParams = {}) =>
			this.request<BizResponseUserDefaultFreeShippingResp, any>({
				path: `/userReceivingAddress/findDefaultFreeShipping`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 用户收货地址相关接口
		 * @name List
		 * @summary 查询收获人列表
		 * @request POST:/userReceivingAddress/list
		 */
		list: (params: RequestParams = {}) =>
			this.request<BizResponseListUserReceivingAddressDTO, any>({
				path: `/userReceivingAddress/list`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 用户收货地址相关接口
		 * @name Save
		 * @summary 新增/修改收获人地址
		 * @request POST:/userReceivingAddress/save
		 */
		save: (req: UserReceivingAddressDTO, params: RequestParams = {}) =>
			this.request<BizResponseVoid, any>({
				path: `/userReceivingAddress/save`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			})
	};
}
