/*
 * @Author: shiguang
 * @Date: 2023-04-27 14:06:40
 * @LastEditors: lihwang_wf@126.com
 * @LastEditTime: 2024-01-03 11:32:38
 * @Description: 配置 axios 相关信息
 */
import qs from 'query-string';

const getQueryEnv = () => {
	const query = qs.parse(window.location.search) as { _env?: string };
	query?._env && localStorage.setItem('_env', query?._env);
	return localStorage.getItem('_env');
};

const getRquestBaseUrlByEnv = (env: string) => {
	const prod2 = 'https://prod-gateway.theckb.com';
	const prodUrl = 'https://gateway-prod.theckb.com';
	const preUrl = 'https://pre-gateway.theckb.com';
	if (env === 'prod') {
		return prodUrl;
	}
	if (env === 'prod2') {
		return prod2;
	}
	if (env === 'pre') {
		return preUrl;
	}
	if (env === 'test') {
		return `https://master-gateway.theckb.com`;
	}
	return `https://${env}-gateway.theckb.com`;
};

// 1. 可以在 url 上配置
// 2. 可以在 代码中 动态改动
// 3. 根据域名处理
export const serviceConfig = (() => {
	return {
		baseURL: (() => {
			const queryEnv = getQueryEnv();
			if (queryEnv) {
				return getRquestBaseUrlByEnv(queryEnv);
			}
			const devUrl = 'https://master-gateway.theckb.com';
			if (window.location.port) return devUrl;
			const arr = window.location.host.split('-');
			let [env] = arr;
			if (arr.length < 2) env = 'prod';
			return getRquestBaseUrlByEnv(env);
		})()
	};
})();
