/*
 * @Author: shiguang
 * @Date: 2023-04-25 10:46:45
 * @LastEditors: lihwang_wf@126.com
 * @LastEditTime: 2024-01-19 14:46:02
 * @Description: App
 */
import Cookie from 'js-cookie';
import React, {
	Suspense,
	createContext,
	lazy,
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react';
import { atom, useAtom, useSetAtom } from 'jotai';
import { ConfigProvider, FloatingBubble } from 'antd-mobile';
import {
	NavigationType,
	RouterProvider,
	RouterProviderProps,
	createBrowserRouter,
	Location,
	useNavigate,
	useLocation
} from 'react-router-dom';
import jaJp from 'antd-mobile/es/locales/ja-JP';
import { useAsyncEffect, useMount } from 'ahooks';
import queryString from 'query-string';
import {
	initThinkingData,
	taSetUser,
	thinkingdata
} from '@/config/buryingPoint';
import routers from './config/router/index';
import { Lang, LocalContext, getQuerylang } from './i18n/init';
import KeepAlive from './component/KeepAlive';
import '@/styles/theme/index.scss';
import './App.css';
import BottomnavigationHomepageDefault from './common/icons/BottomnavigationHomepageDefault';
import {
	TokenSignCookie,
	checkLogin,
	cookieEnv,
	loginEnv,
	logout
} from './config/request/login';
import { request } from './config/request';
import { Order, User } from './Atoms';
import { UserDetailType } from './Atoms/User';
import { EnumStationListValue, mapStationList } from './common/Maps';
import { EnumStationList, Enum_SellStatus } from './common/Enums';
import { ROUTER_INDEX_NAME } from './config/router/goods';
import { isAndroid } from './utils/jsBridge';
import { commonGoBack } from './common/Layout/CustomNavbar';
import { isMobile } from './utils';

global._sniff_app_ = {
	/** 是否是嵌入app中 */
	isApp: false
};
// 修改翻译

// jaJp.InfiniteScroll.noMore = 'メッセージがありません';
interface RouterHistoryItem extends Location {
	navigationType: NavigationType;
}
export const RouterHistoryContext = createContext<RouterHistoryItem[]>([]);

interface PageContentProps {
	children?: React.ReactNode;
}

const isLocal = window.location.hostname === 'localhost';

/** 初始化客服信息数据类型 */
interface ChannelProps {
	systemName?: string;
	systemEmail?: string;
	templateName?: string;
	systemMemberId?: string;
	name?: string;
	email?: string;
}
const { channel, forcePC } = queryString.parse(window.location.search);
export const platRedirect = async () => {
	if (!isMobile() && !forcePC) {
		window.location.href = toPCTheCkb();
	}
};
/** 获取字段 */

// 跳转pc直营地址
export const toPCTheCkb = (path = '/') => {
	let env = process.env.REACT_APP_ENV as loginEnv;
	const envUrl = {
		test: 'https://test-m.3fbox.com',
		pre: 'https://pre-m.3fbox.com',
		prod: 'https://m.3fbox.com'
	};
	if (window.location.port) {
		env = 'test';
	}
	let npath = envUrl[env] ?? 'https://m.3fbox.com' + path;
	if (channel) {
		npath = envUrl[env]
			? envUrl[env] + `?channel=${channel}`
			: 'https://m.3fbox.com' + path + `?channel=${channel}`;
	}
	return npath;
};
const PageContent = (props: PageContentProps) => {
	const { children } = props;
	const navigate = useNavigate();
	const location = useLocation();
	const { pathname, search } = location;
	const routerHistory = useContext(RouterHistoryContext);
	// 初始化购物车数据
	const setCartOriginalData = useSetAtom(Order.atomCartOriginalData);
	const [isLogin, setIsLogin] = useAtom(User.isLogin);
	const [userInfo, setUserinfo] = useAtom(User.userDetail);
	/** 首页是否启用 */
	const setEnableIndex = useSetAtom(User.enableIndex);
	const [_, setMsgCount] = useAtom(User.serviceNum);
	const [curPage, prepage] = routerHistory;

	/** 获取购物车数据 */
	const getCartList = async () => {
		const token = await checkLogin();
		// 是否需要重新请求下购物车列表
		const isNeedRerequestCartList =
			curRouteWhitelist.includes(curPage?.pathname) ||
			preRouteWhitelist.includes(prepage?.pathname) ||
			curRouteWhitelist.includes(pathname);

		// 登录之后路由变化则请求购物车列表
		if (token) {
			// 登录之后清除信息
			sessionStorage.removeItem('isFromOtherDomain');
			setIsLogin(true);
			const userRes =
				await request.customer.getCustomerDetails.getCustomerDetails();
			if (userRes.success) {
				thinkingdata.login(userRes.data?.customerId);
				console.log(thinkingdata);

				const currentUserStationCode = (userRes.data?.stationCode ||
					EnumStationList.JAPAN) as EnumStationListValue;

				const indexShow = mapStationList.get(
					currentUserStationCode
				) as boolean;
				if (!indexShow) {
					setEnableIndex(indexShow);
					navigate(ROUTER_INDEX_NAME, { replace: true });
					return;
				}
				setEnableIndex(indexShow);
				// 如果是路由带过来的参数
				const searchObj = queryString.parse(window.location.search);
				taSetUser(userRes.data, {
					channel: searchObj?.channel,
					channel_group: searchObj.channel_group
				});
				setUserinfo(userRes.data as UserDetailType);
			}
			// 是否需要重新请求购物车列表
			if (isNeedRerequestCartList) {
				const resData = await request.easyOrder.cart.list();
				const { data = [] } = resData || {};
				const cartData = {
					cartNum: data.length ?? 0,
					cartList: data
				};
				setCartOriginalData(cartData);
				// 当token不存在且当前路由为购物车页面时
			}
		} else {
			thinkingdata.logout();
			setIsLogin(false);
		}
	};

	useAsyncEffect(async () => {
		initThinkingData(!!window?.ReactNativeWebView);
		await getCartList();
		// 除了这三个页面，其余的需要清空保存的信息
		if (!isNotNeedClearAddressAndPersonRouter.includes(pathname)) {
			window.sessionStorage.removeItem('selectedAddressId');
			window.sessionStorage.removeItem('selectedPersonInfoId');
		}
	}, [pathname]);

	useEffect(() => {
		initChannel();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLogin, userInfo]);

	/** 初始化客服 */
	const initChannel = () => {
		// todo:用户名 登录了
		const w: any = window;
		// 默认日本的客服key 9ef7076e-b606-45f8-bb06-ec8b98b5fc7f
		const pluginKey = '6ada065d-08b5-4f42-9131-302ff4e4e772';

		const channelInfo: ChannelProps = {
			systemName: undefined,
			systemEmail: undefined,
			templateName: undefined,
			systemMemberId: undefined,
			name: undefined,
			email: undefined
		};

		if (isLogin && Object.keys(userInfo).length !== 0) {
			channelInfo.systemName = userInfo.loginName;
			channelInfo.systemEmail = userInfo.customerEmail;
			channelInfo.templateName =
				userInfo.membership.membershipTemplateName;
			channelInfo.systemMemberId = String(userInfo.customerId);
			channelInfo.name = userInfo.loginName;
			channelInfo.email = window.location.href;
		}
		// eslint-disable-next-line new-cap
		w.ChannelIO('shutdown');
		// eslint-disable-next-line new-cap
		w.ChannelIO('boot', {
			pluginKey,
			profile: {
				systemName: channelInfo.systemName,
				systemEmail: channelInfo.systemEmail,
				templateName: channelInfo.templateName,
				systemMemberId: channelInfo.systemMemberId,
				name: channelInfo.name,
				email: channelInfo.email
			}
		});

		// eslint-disable-next-line new-cap
		w.ChannelIO('hideChannelButton');
	};
	/** 接受app传过来的信息 */
	const handleAppCallbackMessage = useCallback(
		(event) => {
			const postMessageData = event.data;
			const { callbackId, methodData } = postMessageData;
			if (!callbackId) return;
			// webview触发回退
			if (methodData.type === 'DIRCT_goBack') {
				// 如果是路由带过来的参数
				const searchObj = queryString.parse(window.location.search);
				const fromSniffApp = searchObj.fromSniffApp as string;
				commonGoBack({ navigate, curPage, fromSniffApp });
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[curPage, navigate]
	);
	const postMessage = useCallback(() => {
		if (typeof window === undefined) return;
		if (isAndroid) {
			window.document.addEventListener(
				'dirctEvent',
				handleAppCallbackMessage
			);
		} else {
			window.addEventListener('dirctEvent', handleAppCallbackMessage);
		}
	}, [handleAppCallbackMessage]);
	useEffect(() => {
		if (window?.ReactNativeWebView) {
			// 初始化，接受app传来的消息
			postMessage();
		}
	}, [postMessage]);

	useMount(() => {
		// platRedirect();

		const w: any = window;

		// eslint-disable-next-line new-cap
		w.ChannelIO('onBadgeChanged', ((num: number) => {
			setMsgCount(num);
		}) as any);
	});

	return (
		<KeepAlive>
			<Suspense fallback={<div />}>
				<>
					{children}
					{isLocal && (
						<FloatingBubble
							axis="xy"
							magnetic="x"
							style={{
								'--initial-position-top': '2rem',
								'--initial-position-right': '24px',
								'--edge-distance': '24px'
							}}
							onClick={() => {
								navigate('/goods/home', {
									replace: true
								});
							}}
						>
							{/* <AppstoreOutline fontSize={32} /> */}
							<BottomnavigationHomepageDefault fontSize={22} />
						</FloatingBubble>
					)}
				</>
			</Suspense>
		</KeepAlive>
	);
};

const createRouter = (): RouterProviderProps['router'] => {
	const newrouters = [...routers];
	if (process.env.REACT_APP_ENV !== 'prod') {
		newrouters.unshift({
			path: '/dev/login',
			title: 'dev-login',
			component: lazy(() => import('./container/dev/Login'))
		});
	}
	return createBrowserRouter(
		newrouters.map((item) => {
			const Comp = item.component;
			return {
				element: <PageContent>{Comp && <Comp />}</PageContent>,
				path: item.path,
				errorElement: <div>err</div>
			};
		})
		// {
		// 	basename: `${ROUTER_BASENAME}`
		// }
	);
};
export const router = createRouter();
router.subscribe((data) => {
	const { location } = data;
	// 渠道，若是该字段存在，则路由上一直带着
	if (channel) {
		const _search = queryString.stringify({
			...queryString.parse(location.search),
			channel
		});
		setTimeout(() => {
			window.history.replaceState(
				null,
				'',
				location.pathname + '?' + _search
			);
		}, 1);
	}
});

/** 路由白名单 */
const curRouteWhitelist = [
	'/goods/list',
	'/goods/detail',
	'/order/shopcart',
	// '/order/create',
	'/goods/home'
];
/** 路由白名单 */
const preRouteWhitelist = ['/order/create'];
/** 需要清空存储选择的地址和下单人信息的页面 */
const isNotNeedClearAddressAndPersonRouter = [
	'/order/create',
	'/user/address/edit',
	'/user/person/edit'
];

function App() {
	/** 是否显示页面 */
	const [showAppPage, setShowAppPage] = useState(false);
	const [routerHistoryContextValue, setRouterHistoryContextValue] = useState<
		RouterHistoryItem[]
	>([]);
	/** app存储token */
	const goToCheckLogin = async ({ token, appMount }) => {
		// 设置token
		await Cookie.set(TokenSignCookie, token ?? '', {
			path: '/',
			domain: cookieEnv
		});

		// alert(`222222${token}`);

		await checkLogin();
		// 如果是渲染的时候触发的设置token，那么页面不重新加载
		if (!appMount) {
			window.location.reload();
		}
		// 刷新页面
	};
	/** 接受app传过来的信息 */
	const handleAppCallbackMessage = async (event) => {
		const postMessageData = event.data;

		const { callbackId, methodData, baseData } = postMessageData;
		if (!callbackId) return;
		// 类型为登录则去存储token
		if (methodData?.type === 'DIRCT_login') {
			await goToCheckLogin({
				token: methodData.payload.token,
				appMount: false
			});
		}
		if (methodData?.type === 'DIRCT_loginout') {
			logout();
			window.location.reload();
		}
	};
	const postMessage = () => {
		if (typeof window === undefined) return;
		if (isAndroid) {
			window.document.addEventListener(
				'dirctEvent',
				handleAppCallbackMessage
			);
		} else {
			window.addEventListener('dirctEvent', handleAppCallbackMessage);
		}
	};
	useAsyncEffect(async () => {
		/** 获取app url传过来的参数 */
		const appToH5Payload = queryString.parse(window.location.search);
		/** 如果url上携带了token，并且有值 */
		const fromAppNoToken =
			!appToH5Payload.token ||
			appToH5Payload.token === 'null' ||
			appToH5Payload.token === 'undefined';
		// 初始化走路由传过来的token
		const fromSniffApp = appToH5Payload.fromSniffApp as string;
		// h5原有逻辑
		if (!window?.ReactNativeWebView) {
			const token = await checkLogin();
			if (token) {
				sessionStorage.removeItem('isFromOtherDomain');
			}

			setShowAppPage(true);
			return;
		}
		// app和h5通信
		postMessage();
		// 只有初始化时才从页面中获取token
		if (fromSniffApp) {
			// app传token过来，需要注入token
			if (!fromAppNoToken) {
				const goToCheckLoginPayload = {
					token: appToH5Payload.token,
					appMount: true
				};
				await goToCheckLogin(goToCheckLoginPayload);
			}
			// else {
			// 	// 否则走退出逻辑
			// 	logout();
			// }
		}

		setShowAppPage(true);
	}, []);
	router.subscribe((routerData) => {
		setRouterHistoryContextValue((preState) => {
			const [preRouterData] = preState;
			const isEqual = routerData.location.key === preRouterData?.key;
			if (isEqual) return preState;
			return [
				{
					...routerData.location,
					navigationType: routerData.historyAction
				},
				...preState
			];
		});
	});
	if (!showAppPage) return null;

	return (
		<div id="J_B2B" className="App">
			<RouterHistoryContext.Provider value={routerHistoryContextValue}>
				<LocalContext.Provider value={getQuerylang()}>
					<ConfigProvider locale={jaJp}>
						<RouterProvider
							router={router}
							fallbackElement={<div>fallbackElement</div>}
						/>
					</ConfigProvider>
				</LocalContext.Provider>
			</RouterHistoryContext.Provider>
		</div>
	);
}

export default App;
