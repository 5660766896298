import React, { useEffect, useRef } from 'react';
import styles from './style.module.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
  size?: string | number;
  width?: string | number;
  height?: string | number;
  spin?: boolean;
  rtl?: boolean;
  color?: string;
  fill?: string;
  stroke?: string;
}
export default function Househome(props: IconProps) {
  const root = useRef<SVGSVGElement>(null);
  const {
    size = '1em',
    width,
    height,
    spin,
    rtl,
    color,
    fill,
    stroke,
    className,
    ...rest
  } = props;
  const _width = width || size;
  const _height = height || size;
  const _stroke = stroke || color;
  const _fill = fill || color;
  useEffect(() => {
    if (!_fill) {
      (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-fill]').forEach(item => {
        item.setAttribute('fill', item.getAttribute('data-follow-fill') || '');
      });
    }
    if (!_stroke) {
      (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-stroke]').forEach(item => {
        item.setAttribute('stroke', item.getAttribute('data-follow-stroke') || '');
      });
    }
  }, [stroke, color, fill]);
  return <svg ref={root} width={_width} height={_height} viewBox="0 0 16 16" preserveAspectRatio="xMidYMid meet" fill="none" role="presentation" xmlns="http://www.w3.org/2000/svg" className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()} {...rest}>
            <g><path d="M8 8c-1.172 0-2 .84-2 1.733v3.6a.667.667 0 1 1-1.333 0v-3.6c0-1.758 1.56-3.066 3.333-3.066 1.773 0 3.334 1.308 3.334 3.066v3.6a.667.667 0 1 1-1.334 0v-3.6C10 8.841 9.172 8 8 8Z" clipRule="evenodd" fillRule="evenodd" data-follow-fill="#1C2026" fill={_fill} /><path d="M7.596.803a.667.667 0 0 1 .808 0l6 4.57a.667.667 0 0 1 .263.53V12a2 2 0 0 1-2 2H3.334a2 2 0 0 1-2-2V5.904c0-.208.097-.404.262-.53l6-4.57Zm-4.93 5.431V12c0 .368.3.667.668.667h9.333a.667.667 0 0 0 .667-.667V6.234L8 2.171 2.667 6.234Z" clipRule="evenodd" fillRule="evenodd" data-follow-fill="#1C2026" fill={_fill} /></g>
        </svg>;
}