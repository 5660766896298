/*
 * @Author: shiguang
 * @Date: 2023-07-04 22:27:10
 * @LastEditors: lihwang_wf@126.com
 * @LastEditTime: 2023-12-01 17:10:13
 * @Description: Description
 */

import qs from 'query-string';
import { createContext } from 'react';
import extractConfig from '@/i18n/locales/extractConfig.json';
import jp from '@/i18n/locales/ja_JP.json';
import kr from './locales/ko_KR.json';
/**
 * 国家及货币单位
 */
export enum Site {
	/**
	 * 日本站
	 */
	JA = 'JapanStation',
	/**
	 * 韩国站
	 */
	KO = 'KoreaStation',
	/**
	 * 英国站
	 */
	EN = 'UkStation'
}
/** */
export enum Local {
	ZH = 'zh',
	/** 日文 */
	JA = 'ja',
	/** 韩文 */
	KO = 'ko'
}

/** */
export enum Lang {
	zh_CN = 'zh_CN',

	/** 日文 */
	ja_JP = 'ja_JP',
	/** 韩文 */
	ko_KR = 'ko_KR'
}
/**
 * 获取站点信息
 * @returns
 */
export const getStationCode = () => {
	return Site[initLang] || Site.JA;
};
export const getQuerylang = (): Lang => {
	const query = qs.parse(window.location.search) as { lang: Local };

	return (
		{
			[Local.ZH]: Lang.zh_CN,

			[Local.JA]: Lang.ja_JP,

			[Local.KO]: Lang.ko_KR
		}[query.lang] || Lang.ja_JP
	);
};

const getOldLocalLang = () => {
	const lang = localStorage.getItem('__lang__') || Local.ZH;
	return (
		{
			[Local.ZH]: Lang.zh_CN,

			[Local.JA]: Lang.ja_JP,

			[Local.KO]: Lang.ko_KR
		}[lang] || Lang.zh_CN
	);
};
export const getLang = () => {
	return Lang.ja_JP;
	// const langs = ['zh', 'ja', 'ko', 'en', 'ko', 'th']
	// const queryLang = getQuerylang();
	// if (queryLang) {
	//     localStorage.setItem('lang', queryLang);
	//     const _queryLang =
	//   {
	//       [Lang.zh_CN]: Local.ZH,
	//       [Lang.ja_JP]: Local.JA,
	//       [Lang.ko_KR]: Local.KO,
	//   }[queryLang] ;

	//     localStorage.setItem('__lang__', _queryLang);
	//     return queryLang;
	// }

	// const oldLocalLang = getOldLocalLang();
	// if (oldLocalLang) {
	//     localStorage.setItem('lang', oldLocalLang);
	//     return oldLocalLang;
	// }
	// const lang = localStorage.getItem('lang');
	// if (lang) {
	//     return lang;
	// }
	// localStorage.setItem('lang', Lang.ja_JP);
	// return Lang.ja_JP;
};
const initLang = getLang();

export const LocalContext = createContext(initLang);

export const getUnTranslateData = (data) => {
	return Object.entries(data).reduce((pre, cur: any) => {
		const [key, config] = cur;
		pre[key] = Object.entries(config).reduce((_pre, _cur) => {
			const [_key, _value] = _cur;
			if ('' === _value) {
				_pre[_key] = '';
			}
			return _pre;
		}, {});
		return pre;
	}, {});
};

export const mergeDataByOtherConfig = (data, otherConfig) => {
	return Object.entries(data).reduce((pre, cur: any) => {
		const [key, config] = cur;
		pre[key] = Object.entries(config).reduce((_pre, _cur) => {
			const [_key, _value] = _cur;
			if (_value === '' && otherConfig[_key]) {
				_pre[_key] = otherConfig[_key];
			} else {
				_pre[_key] = _value;
			}
			return _pre;
		}, {});
		return pre;
	}, {});
};
